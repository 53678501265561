import { GroupTypeUser, t } from "@/global";
import { NameEditor } from "@/pages/DeviceList/DevicesTable";
import { addAbRule, updateAbRule } from "@/services/api";
import { API } from "@/services/typings";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import { ActionType } from "@ant-design/pro-table";
import { useIntl } from "@umijs/max";
import { message } from "antd";
import { useState } from "react";

type RuleFormProps = {
  isCreate: boolean;
  visible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  actionRef: React.MutableRefObject<ActionType | undefined>;
  initialValues: API.AbRule | undefined;
  profile: API.SharedAbProfile,
}

export enum ShareRule {
  READ = 1,
  READ_WRITE = 2,
  FULL_CONTROL = 3,
}

const RuleForm: React.FC<RuleFormProps> = (props) => {
  const intl = useIntl();
  const [tableName, setTableName] = useState<string | undefined>(undefined);

  const plain = (text: string, defaultMessage = '') => t(`pages.AddressBook.${text}`, true, defaultMessage || text, intl) as string;

  const handleCreate = async (data: any) => {
    const hide = message.loading(t('Adding'));
    try {
      const obj: { [key: string]: any } = {
        rule: data.rule,
        guid: props.profile.guid,
      };
      if (data.table === "user") {
        if (!data.name) {
          throw "User name is required";
        }
        obj["user"] = data.name;
      } else if (data.table === "grp") {
        if (!data.name) {
          throw "Group name is required";
        }
        obj["group"] = data.name;
      } else if (data.table === "team") {
        // do nothing
      } else {
        throw "Invalid table";
      }
      await addAbRule({
        data: obj,
      });
      hide();
      message.success(t('Added successfully'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Adding failed, please try again!'),
      );
      return false;
    }
  };

  const handleUpdate = async (data: any) => {
    const hide = message.loading(t('Updating'));
    try {
      await updateAbRule({
        data: {
          guid: props.initialValues?.guid,
          rule: data.rule,
        }
      });
      hide();
      message.success(t('Update is successful'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Update failed, please try again!'),
      );
      return false;
    }
  };

  const onClose = () => {
    props.setModalVisible(false);
    setTableName(undefined);
  };

  return (
    <ModalForm
      title={t(props.isCreate ? 'Create' : `Edit permission of ${getNameOfRule(props.initialValues)}`)}
      width="400px"
      open={props.visible}
      initialValues={props.initialValues}
      onOpenChange={props.setModalVisible}
      onFinish={async (values) => {
        const success = await (props.isCreate ? handleCreate : handleUpdate)(values);
        if (success) {
          if (props.actionRef.current) {
            props.actionRef.current.reload();
          }
          onClose();
        }
      }}
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        onCancel: () => {
          onClose();
        },
      }}
    >
      {props.isCreate && <ProFormSelect<string>
        fieldProps={{
          onChange: (value) => {
            setTableName(value);
          }
        }}
        rules={[
          {
            required: true,
          },
        ]}
        width="md"
        name="table"
        label={t('Type')}
        options={[
          { value: "user", label: plain('User') },
          { value: "grp", label: plain('Group') },
          { value: "team", label: plain('Everyone') },
        ]}
      />}
      {props.isCreate && tableName === "user" && <NameEditor table={"user"} field={"name"} label={"Name"} required={true} />}
      {props.isCreate && tableName === "grp" && <NameEditor table={"grp"} field={"name"} label={"Name"} required={true} type={GroupTypeUser} />}
      <ProFormSelect<ShareRule>
        fieldProps={{}}
        rules={[
          {
            required: true,
          },
        ]}
        width="md"
        name="rule"
        label={plain('Permission')}
        tooltip={plain("permission_tip", permissionTooltip)}
        options={[
          { value: ShareRule.READ, label: plain('Read-only') },
          { value: ShareRule.READ_WRITE, label: plain('Read/Write') },
          { value: ShareRule.FULL_CONTROL, label: plain('Full Control') },
        ]}
      />
    </ModalForm>
  )
}

export default RuleForm;


export function getNameOfRule(rule: API.AbRule | undefined): string {
  if (!rule) {
    return "-";
  }
  if (rule?.user && rule?.group) {
    return '-';
  }
  if (rule?.user) {
    return rule?.user;
  }
  if (rule?.group) {
    return rule.group;
  }
  return "Everyone";
}

export function getTypeOfRule(rule: API.AbRule | undefined): string {
  if (!rule) {
    return "-";
  }
  if (rule?.user && rule?.group) {
    return '-';
  }
  if (rule.user) {
    return "User";
  }
  if (rule.group) {
    return "Group";
  }
  return "Everyone";
}

export const permissionTooltip = "Read-only: Can view devices and tags; Read/Write: Can edit devices and tags; Full Control: Can re-share, delete or rename the address book";
