import { devices, updateDevice } from "@/services/api"
import { Button, Col, message, Modal, Row, Typography } from "antd"
import { useEffect, useRef, useState } from "react"
import { API } from '@/services/typings';
import ProTable, { ActionType } from "@ant-design/pro-table";
import { getTablePaginationConfig, t } from "@/global";
import { CloseOutlined } from "@ant-design/icons";
import { DEVICE_LIST_SORT_STATE_KEY, deviceColumns } from "@/pages/DeviceList/DevicesTable";
import { useModel } from "@umijs/max";
import { getLocalSortState, handleSortStateChange } from "@/components/Others/table_sort";

type DevicesProps = {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  userGuid: string,
  userName: string,
}

const app: React.FC<DevicesProps> = (props) => {
  const [devs, setDevs] = useState<(API.DeviceListItem)[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [sortState, setSortState] = useState<API.SortState>(() => getLocalSortState(DEVICE_LIST_SORT_STATE_KEY));
  const actionRef = useRef<ActionType>();
  const { initialState } = useModel('@@initialState');
  const is_admin = initialState?.user?.is_admin;

  useEffect(() => {
    if (props.visible) {
      actionRef.current?.reload();
    }
  }, [props.visible]);

  const onSave = async () => {
    const hide = message.loading(t('Updating'));
    const changeTochecked = devs.filter((d: API.DeviceListItem) => d.user !== props.userGuid && selectedRowKeys.indexOf(d.id) >= 0);
    const changeToUnchecked = devs.filter((d: API.DeviceListItem) => d.user === props.userGuid && selectedRowKeys.indexOf(d.id) < 0);

    try {
      for (let i = 0; i < changeTochecked.length; i++) {
        let d = changeTochecked[i];
        let data = {
          guid: d.guid,
          id: d.id,
          user_name: props.userName,
        }
        await updateDevice({ data });
      }
      for (let i = 0; i < changeToUnchecked.length; i++) {
        let d = changeToUnchecked[i];
        let data = {
          guid: d.guid,
          id: d.id,
          user_name: "",
        }
        await updateDevice({ data });
      }
    } catch (e: any) {
      hide();
      message.error(e);
      actionRef.current?.reload();
      return;
    }
    hide();
    let changedCount = changeTochecked.length + changeToUnchecked.length;
    message.success(`${changedCount} record${changedCount === 1 ? " is" : "s are"} successfully changed`)
    actionRef.current?.reload();
  }

  const onReset = async () => {
    actionRef.current?.reset?.();
  }

  const saveButtonEnabled = () => {
    const changeTochecked = devs.filter((d: API.DeviceListItem) => d.user !== props.userGuid && selectedRowKeys.indexOf(d.id) >= 0);
    const changeToUnchecked = devs.filter((d: API.DeviceListItem) => d.user === props.userGuid && selectedRowKeys.indexOf(d.id) < 0);
    let changedCount = changeTochecked.length + changeToUnchecked.length;
    return changedCount > 0;
  }

  const onClose = () => {
    props.setVisible(false);
    setDevs([]);
    setSelectedRowKeys([]);
  }

  const columns = deviceColumns({
    actionRef,
    is_admin: is_admin,
    is_select: true,
    totalRecords,
    sortState,
    setSortState,
  });

  return <Modal
    open={props.visible}
    width="90vw"
    style={{
      overflowX: "hidden",
    }}
    footer={saveButtonEnabled() ?
      <Row justify="center" gutter={16}>
        <Col>
          <Button onClick={onReset}>{t("Reset")}</Button>
        </Col>
        <Col>
          <Button onClick={onSave} type="primary">{t("Save")}</Button>
        </Col>
      </Row>
      : null}
    onCancel={onClose}
    destroyOnClose={true}
    title={<span>Assign devices to user <Typography.Text mark>{props.userName}</Typography.Text></span>}
    closeIcon={<CloseOutlined />}
  >
    <ProTable<API.DeviceListItem, API.PageParams >
      columnsState={{ persistenceType: 'localStorage', persistenceKey: 'use_assign_devices_columns_state' }}
      rowKey={"id"}
      actionRef={actionRef}
      search={{ filterType: 'light' }}
      defaultSize="middle"
      columns={columns as any}
      request={async (params, sort, _filter) => {
        const result = await devices(params, sort);
        setTotalRecords(result.total || 0);
        return result;
      }}
      postData={(items: (API.DeviceListItem)[]) => {
        setDevs(items);
        setSelectedRowKeys(items.filter((i: API.DeviceListItem) => i.user === props.userGuid).map((d) => d.id));
        return items;
      }}
      rowSelection={{
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys) => {
          setSelectedRowKeys(selectedRowKeys as string[]);
        },
      }}
      pagination={getTablePaginationConfig('user_assign_device_list')}
      style={{
        paddingLeft: '5vw', paddingRight: '5vw'
      }}
      onChange={(_, __, sorter) => handleSortStateChange(sorter, actionRef, DEVICE_LIST_SORT_STATE_KEY, setSortState)}
    />
  </Modal >
}

export default app;

