import type { TablePaginationConfig } from 'antd';
import { message, Button, Divider, Tooltip } from 'antd';
import React from 'react';
import { useEffect, useState } from "react";
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import type { API } from '@/services/typings';
import { ExclamationCircleOutlined, SyncOutlined, StopOutlined, CloseOutlined, PlayCircleOutlined, ClockCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { getTablePaginationConfig } from '../../../global';
import { PlusOutlined } from '@ant-design/icons';
import { listCustomClient, executeCustomClient } from '@/services/api';
import type { FuncFormatMessage, Pagination } from '..';
import { CustomClientPage, getDelete, getUpgradeLicenseMessage, gotoPage, os2Icon } from '..';
import { getLocalSortState, handleSortStateChange, TableSorterTooltip } from '@/components/Others/table_sort';

export const CUSTOM_CLIENT_SORT_STATE_KEY = 'custom_client_sort_state';
const ID_SORT_KEY = 'custom_client_id_sort_key';
const CUSTOM_CLIENT_SORT_OPTIONS = [
  { value: 'info_app_name', label: 'Application name' },
  { value: 'os', label: 'OS' },
];
export type CustomClientTableProps = {
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  isAvailable: boolean;
  plain: FuncFormatMessage;
  actionRef: React.MutableRefObject<ActionType | undefined>;
}

type BuildStatusProps = {
  record: API.CustomClientListItem;
  plain: FuncFormatMessage;
}


const BuildStatus: React.FC<BuildStatusProps> = ({ plain, record }) => {
  const [status, setStatus] = useState(record.status);
  const [loading, setLoading] = useState(false);
  const fetched_at = status?.fetched_at;
  const completed_at = status?.completed_at as string;

  let timeouted = false;
  if (fetched_at && !completed_at) {
    const d = (new Date().getTime() - new Date(fetched_at).getTime()) / 1000;
    timeouted = d > 1800; // 30 minutes
  }

  useEffect(() => {
    if (!timeouted && status?.id && !status?.completed_at) {
      const timeout = setTimeout(async () => {
        try {
          const status = await executeCustomClient(record.guid, 'status');
          setStatus(status);
        } catch (e) {
          //
        }
      }, 16000);
      return () => clearTimeout(timeout);
    }
    return;
  }, [status]);

  const getDateString = (d: string) => {
    let d2 = new Date(d);
    if (d2.toLocaleDateString() === new Date().toLocaleDateString()) {
      return d2.toLocaleTimeString();
    }
    return d2.toLocaleString();
  }
  const task_id = status?.id;
  const build = (size?: number) => <Button type="link" disabled={loading} onClick={async () => {
    setLoading(true);
    try {
      const status = await executeCustomClient(record.guid, 'create');
      setStatus(status);
    } catch (e) {
      message.error(plain('An error occurred while building') + ', ' + e);
    }
    setLoading(false);
  }}>
    <Tooltip title={plain("Build")}><PlayCircleOutlined style={{ fontSize: size }} /></Tooltip>
  </Button>;
  if (!task_id) {
    return build(18);
  }

  const waiting_length = status?.waiting_length;

  if (completed_at) {
    const tm = <span style={{ fontSize: 10, opacity: 0.5 }}>{plain('Completed at %s, this file will be deleted in 1 day.').replace('%s', getDateString(completed_at))}</span>;
    return <>
      {status?.error ? <Tooltip title={status.error}><ExclamationCircleOutlined style={{ color: 'red' }} /></Tooltip> :
        <Tooltip title={tm}>
          <a target='blank' style={{ textDecoration: 'underline', color: '#008000' }}
            href={status?.file?.startsWith('http') ? status?.file : 'https://rustdesk.com/build/tasks/' + status?.id + '/files/' + status?.file}>
            {plain('Download')}</a>
        </Tooltip>}
      {build()}
    </>;
  }
  const refresh = <Tooltip title={plain('Refresh status manually')}><Button type="link" icon={<ReloadOutlined />} onClick={async () => {
    setLoading(true);
    try {
      const status = await executeCustomClient(record.guid, 'status');
      setStatus(status);
    } catch (e) {
      message.error(plain('An error occurred while refreshing the status') + ', ' + e);
    }
    setLoading(false);
  }} disabled={loading} /></Tooltip>;
  const stop = (action: string) => <Tooltip title={plain(action) + ' ' + plain('the building task')}><Button type="link" icon={action == 'Stop' ? <StopOutlined style={{ color: 'red' }} /> : <CloseOutlined style={{ color: 'red' }} />} onClick={async () => {
    setLoading(true);
    try {
      const status = await executeCustomClient(record.guid, 'delete');
      setStatus(status);
    } catch (e) {
      message.error(plain('An error occurred while deleting the building task') + ', ' + e);
    }
    setLoading(false);
  }} disabled={loading} /></Tooltip>;
  if (!fetched_at) {
    const tm = <span style={{ fontSize: 10, opacity: 0.5 }}>{plain('created at %s').replace('%s', getDateString(status?.created_at))}</span>;
    if (waiting_length) {
      return <><Tooltip title={<>{plain('Queued, %s jobs ahead').replace('%s', waiting_length)}<br /> {tm}</>}><ClockCircleOutlined style={{ fontSize: 20 }} /></Tooltip>
        &nbsp;&nbsp;{refresh}{stop('Cancel')}
      </>;
    } else {
      return <><Tooltip title={<>{plain('Queued, waiting to start')}<br /> {tm}</>}><ClockCircleOutlined style={{ fontSize: 20 }} /></Tooltip>
        &nbsp;&nbsp;{refresh}{stop("Cancel")}
      </>;
    }
  }
  const tm = <span style={{ fontSize: 10, opacity: 0.5 }}>{plain('Started at %s').replace('%s', getDateString(fetched_at))}</span>;
  if (timeouted) {
    return <>
      <Tooltip title={<>{plain("Build timeout")}<br />{tm}</>}><ExclamationCircleOutlined style={{ color: 'red' }} /></Tooltip>
      {build()}
    </>;
  }
  return <><Tooltip title={<>{plain("Building")}<br />{tm}</>}><SyncOutlined spin /></Tooltip>
    &nbsp;&nbsp;{refresh} {stop('Stop')}
  </>
}

const CustomClientTable: React.FC<CustomClientTableProps> = ({
  pagination, setPagination, isAvailable, plain, actionRef
}) => {
  const idSortKey = localStorage.getItem(ID_SORT_KEY) || 'info_app_name';
  const [customClientSortState, setCustomClientSortState] = useState<API.SortState>(() => getLocalSortState(CUSTOM_CLIENT_SORT_STATE_KEY));

  const columns: ProColumns<API.CustomClientListItem>[] = [
    {
      title: plain('Name'),
      dataIndex: 'name',
      render: (dom, entity) => {
        return (
          <a
            onClick={() => gotoPage(CustomClientPage.PageView, entity.guid)}
          >
            {dom}
          </a>
        );
      },
      key: 'name',
      sorter: true,
      sortOrder: customClientSortState.columnKey === 'name' ? customClientSortState.order : undefined,
      showSorterTooltip: false,
    },
    {
      title: plain('Application name'),
      dataIndex: 'app_name',
      render: (dom, entity) => {
        const getAppName = () => {
          return <>
            {entity.appName}
          </>;
        }
        return <>{os2Icon(entity.os)}&nbsp;&nbsp;{getAppName()}</>;
      },
      key: idSortKey,
      sorter: true,
      sortOrder: customClientSortState.columnKey === idSortKey ? customClientSortState.order : undefined,
      showSorterTooltip: {
        title: <TableSorterTooltip actionRef={actionRef} idSortKey={ID_SORT_KEY} sortStateKey={CUSTOM_CLIENT_SORT_STATE_KEY} options={CUSTOM_CLIENT_SORT_OPTIONS as any} setSortState={setCustomClientSortState} />,
      },
      hideInSearch: true,
    },
    {
      title: plain('Application name'),
      dataIndex: 'app_name',
      hideInTable: true,
    },
    {
      title: plain('Build'),
      dataIndex: 'status',
      search: false,
      valueType: 'textarea',
      render: (dom, entity) => {
        return <BuildStatus record={entity} plain={plain} />;
      }
    },
    {
      title: plain('Note'),
      dataIndex: 'note',
      search: false,
      valueType: 'textarea',
      key: 'note',
      sorter: true,
      sortOrder: customClientSortState.columnKey === 'note' ? customClientSortState.order : undefined,
      showSorterTooltip: false,
    },
    {
      title: plain('Action'),
      search: false,
      render: (text, record: API.CustomClientListItem) => <>
        <a onClick={() => gotoPage(CustomClientPage.PageEdit, record.guid)}>
          {plain('Edit')}
        </a>
        <Divider type="vertical" />
        <a onClick={() => gotoPage(CustomClientPage.PageDup, record.guid)}>
          {plain('Duplicate')}
        </a>
        <Divider type="vertical" />
        {getDelete(<a style={{ color: 'red' }}>{plain('Delete')}</a>, record.guid, actionRef)}
      </>,
    }
  ];

  const handleTableChange = (p: TablePaginationConfig, _c: any, s: any) => {
    setPagination({ current: p.current, pageSize: p.pageSize });
    handleSortStateChange(s, actionRef, CUSTOM_CLIENT_SORT_STATE_KEY, setCustomClientSortState);
  };

  return <ProTable<API.CustomClientListItem, API.PageParams>
    columnsState={{ persistenceType: 'localStorage', persistenceKey: 'custom_client_columns_state' }}
    actionRef={actionRef}
    rowKey="guid"
    search={{
      labelWidth: 120,
      optionRender: (searchConfig, props, dom) => [
        <Tooltip key={'reset tooltip'} title={isAvailable ? undefined : getUpgradeLicenseMessage(plain)}>
          {dom[0]}
        </Tooltip>,
        <Tooltip key={'search tooltip'} title={isAvailable ? undefined : getUpgradeLicenseMessage(plain)}>
          {dom[1]}
        </Tooltip>
      ],
    }}
    toolBarRender={() => [
      <Tooltip key={'create tooltip'} title={isAvailable ? undefined : getUpgradeLicenseMessage(plain)}>
        <Button
          type="primary"
          key="create"
          onClick={() => gotoPage(CustomClientPage.PageNew, undefined)}
          disabled={!isAvailable}
        >
          <PlusOutlined /> {plain('Create')}
        </Button>
      </Tooltip>,
    ]}
    request={isAvailable ? listCustomClient : undefined}
    columns={columns}
    pagination={{ ...getTablePaginationConfig('custom_client_list'), ...pagination }}
    onChange={(p, _c, s) => handleTableChange(p, _c, s)}
  />;
};

export default CustomClientTable;
