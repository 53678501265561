import { getTablePaginationConfig, t } from "@/global";
import { deleteRecycledAbPeersByGuid, emptyRecycleBin, getAbPeers, restoreAbPeers } from "@/services/api";
import { API } from "@/services/typings";
import { AndroidFilled, AppleFilled, CloseOutlined, DeleteOutlined, KeyOutlined, QqCircleFilled, WindowsFilled } from "@ant-design/icons";
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table";
import { Button, Col, Divider, Flex, Modal, Popconfirm, Row, Tooltip, Typography, message } from "antd";
import { Fragment, useRef, useState } from "react";
import { buildTag } from "./TagsCard";
import { useIntl } from "@umijs/max";
import { DETAIL_TABLE_SORT_STATE_KEY, ID_SORT_KEY } from "./DeviceTable";
import { getLocalSortState, handleSortStateChange, TableSorterTooltip } from "@/components/Others/table_sort";

type RecycleBinProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  personal: boolean;
  profile: API.SharedAbProfile;
  abTags: API.AbTag[];
  closeChangedCallback: () => void;
};

const DetailTable: React.FC<RecycleBinProps> = (props) => {
  const actionRef = useRef<ActionType>();
  const [selectedRowsState, setSelectedRows] = useState<API.AbPeer[]>([]);
  const [sortState, setSortState] = useState<API.SortState>(() => getLocalSortState(DETAIL_TABLE_SORT_STATE_KEY));
  const intl = useIntl();

  const plain = (text: string, defaultMessage = '') => t(`pages.AddressBook.${text}`, true, defaultMessage || text, intl) as string;

  const DeleteConfirm = (props: { children: any; onClick: () => void, isEmpty: boolean, count: number }) => (
    <Popconfirm
      title={props.isEmpty ? plain('Are you sure yout want to empty the recycle bin?') : t('deleteConfirm', true, `Are you sure yout want to delete ${props.count == 1 ? 'it' : `these ${props.count} items`} permanently?`)}
      onConfirm={() => props.onClick()}
      okText="Yes"
      cancelText="No"
    >
      {props.children}
    </Popconfirm>
  );

  const handleRestore = async (rows: API.AbPeer[]) => {
    const hide = message.loading(t('Restoring'));
    try {
      await restoreAbPeers(
        props.profile.guid,
        {
          data: rows.map((x) => x.id),
        },
      );
      hide();
      message.success(t('Restoration is successful'));
      actionRef.current?.reload();
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Restoration failed, please try again!'),
      );
      return false;
    }
  };

  const handleDelete = async (rows: API.AbPeer[]) => {
    const hide = message.loading(t('Deleting'));
    try {
      await deleteRecycledAbPeersByGuid(
        props.profile.guid,
        {
          data: rows.map((x) => x.guid),
        },
      );
      hide();
      message.success(t('Deletetion is successful'));
      actionRef.current?.reload?.();
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Deletetion failed, please try again!'),
      );
      return false;
    }
  };

  const handleEmpty = async () => {
    const hide = message.loading(t('Executing'));
    try {
      await emptyRecycleBin(
        props.profile.guid,
      );
      hide();
      message.success(t('Empty successfully'));
      actionRef.current?.reload?.();
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Empty failed, please try again!'),
      );
      return false;
    }
  };

  const buildTagWrapper = (peer: API.AbPeer) => {
    let tags = [];
    const peerTags = peer.tags ?? [];
    for (let i = 0; i < props.abTags.length; i++) {
      const contains = peerTags.find((x) => x == props.abTags[i].name);
      if (contains) {
        tags.push(buildTag(props.abTags[i], false, undefined, undefined, undefined));
      }
    }
    return <Flex wrap="wrap" gap="small">{tags}</Flex>
  }
  const idSortKey = localStorage.getItem(ID_SORT_KEY) || 'ab_detail_id';
  const columns: ProColumns<API.AbPeer>[] = [
    {
      title: t('ID'),
      dataIndex: 'id',
      render: (dom, entity) => {
        const os = entity.platform;
        return (<>
          {os && <Tooltip title={os}>
            {os == 'Windows' && <WindowsFilled />}
            {os == 'Linux' && <QqCircleFilled />}
            {os == 'Android' && <AndroidFilled />}
            {os == 'Mac OS' && <AppleFilled />}
            {os == 'iOS' && <AppleFilled />}
            &nbsp;&nbsp;
          </Tooltip>}
          <span>{dom}</span>
        </>
        );
      },
      key: idSortKey,
      sorter: true,
      sortOrder: sortState.columnKey === idSortKey ? sortState.order : undefined,
      showSorterTooltip: {
        title: <TableSorterTooltip actionRef={actionRef} idSortKey={ID_SORT_KEY} sortStateKey={DETAIL_TABLE_SORT_STATE_KEY} options={[
          { value: "ab_detail_id", label: "ID" },
          { value: "ab_detail_os", label: "OS" },
        ]} setSortState={setSortState} />,
      },
    },
    {
      title: plain('Device'),
      tooltip: t('username@device_name'),
      hideInSearch: true,
      render: (dom, entity) => {
        if (entity.username && entity.hostname) {
          return entity.username + '@' + entity.hostname;
        } else {
          return entity.hostname;
        }
      },
      key: 'ab_detail_device_username',
      sorter: true,
      sortOrder: sortState.columnKey === 'ab_detail_device_username' ? sortState.order : undefined,
      showSorterTooltip: false,
    },
    {
      title: plain('Alias'),
      dataIndex: 'alias',
      key: 'alias',
      sorter: true,
      sortOrder: sortState.columnKey === 'alias' ? sortState.order : undefined,
      showSorterTooltip: false,
    },
    {
      title: t('Password'),
      search: false,
      tooltip: plain('Whether a password is set'),
      render: (text, record) => (
        record.password && <KeyOutlined />
      ),
    },
    {
      title: plain('Tags'),
      search: false,
      render: (text, record) => (
        buildTagWrapper(record)
      ),
    },
    {
      title: t('Note'),
      dataIndex: 'note',
      key: 'a_note',
      sorter: true,
      sortOrder: sortState.columnKey === 'a_note' ? sortState.order : undefined,
      showSorterTooltip: false,
    },
    {
      title: t('Action'),
      search: false,
      width: 160,
      render: (text, record) => (
        <Fragment>
          <a
            onClick={() => handleRestore([record])}
          >
            {t('Restore')}
          </a>
          <Divider type="vertical" />
          <DeleteConfirm
            onClick={() => handleDelete([record])}
            isEmpty={false}
            count={1}
          >
            <a>{t('Delete')}</a>
          </DeleteConfirm>
        </Fragment>
      ),
    },
  ];

  const onClose = () => {
    props.setVisible(false);
    props.closeChangedCallback();
  }

  return (
    <Modal
      open={props.visible}
      width="90vw"
      style={{
        overflowX: "hidden",
      }}
      onCancel={onClose}
      destroyOnClose={true}
      title={<span>Restore devices for <Typography.Text mark>{props.profile.name}</Typography.Text></span>}
      closeIcon={<CloseOutlined />}
      footer={selectedRowsState?.length > 0 ?
        <Row justify="center" gutter={16}>
          <Col>
            <Button type="primary" onClick={async () => {
              await handleRestore(selectedRowsState);
              setSelectedRows([]);
            }}>{t("Restore")}</Button>
          </Col>
          <Col>
            <DeleteConfirm children={<Button type="primary" danger>{t('Delete')}</Button>} onClick={async () => {
              await handleDelete(selectedRowsState);
              setSelectedRows([]);
            }}
              isEmpty={false}
              count={selectedRowsState.length}
            >
            </DeleteConfirm>
          </Col>
        </Row>
        : null}
    >
      <Fragment>
        <ProTable<API.AbPeer, API.PageParams & { ab: string, recycled: string } >
          headerTitle={plain('Devices')}
          actionRef={actionRef}
          columnsState={{ persistenceType: 'localStorage', persistenceKey: 'ab_recycle_bin_columns_state' }}
          rowKey="id"
          search={false}
          params={{
            ab: props.profile.guid,
            recycled: '',
          }}
          request={getAbPeers}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows) => {
              setSelectedRows(selectedRows);
            },
          }}
          pagination={getTablePaginationConfig("ab_recycle_bin")}
          toolBarRender={() => [
            <DeleteConfirm
              onClick={async () => handleEmpty()}
              isEmpty={true}
              count={0}
            >
              <Button
                type="primary"
                danger
              >
                <DeleteOutlined /> {plain('Empty Recycle Bin')}
              </Button>
            </DeleteConfirm>
          ]}
          onChange={(_, __, sorter) => handleSortStateChange(sorter, actionRef, DETAIL_TABLE_SORT_STATE_KEY, setSortState)}
        />
      </Fragment>
    </Modal >
  );
};

export default DetailTable;
