import { getTablePaginationConfig, t } from "@/global";
import { deleteAbRules, getAbRulesInPage } from "@/services/api";
import { API } from "@/services/typings";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table";
import { Button, Col, Divider, Modal, Popconfirm, Row, Typography, message } from "antd";
import { Fragment, useRef, useState } from "react";
import { useIntl } from "@umijs/max";
import RuleForm, { getNameOfRule, getTypeOfRule, permissionTooltip, ShareRule } from "./RuleForm";

type RuleTableModalProps = {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  profile: API.SharedAbProfile;
};

const RuleTableModal: React.FC<RuleTableModalProps> = (props) => {
  const actionRef = useRef<ActionType>();;
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [selectedRowsState, setSelectedRows] = useState<API.AbRule[]>([]);
  const [currentRow, setCurrentRow] = useState<API.AbRule>();
  const intl = useIntl();

  const plain = (text: string, defaultMessage = '') => t(`pages.AddressBook.${text}`, true, defaultMessage || text, intl) as string;

  const DeleteConfirm = (props: { children: any; onClick: () => void }) => (
    <Popconfirm
      title={t('deleteConfirm', true, 'Are you sure to delete?')}
      onConfirm={() => props.onClick()}
      okText="Yes"
      cancelText="No"
    >
      {props.children}
    </Popconfirm>
  );

  const handleDelete = async (rows: API.AbRule[]) => {
    const hide = message.loading(t('Deleting'));
    try {
      await deleteAbRules(
        {
          data: rows.map((x) => x.guid),
        },
      );
      hide();
      message.success(t('Deletetion is successful'));
      actionRef.current?.reloadAndRest?.();
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Deletetion failed, please try again!'),
      );
      return false;
    }
  };

  const handleEdit = async (row: API.AbRule) => {
    setCurrentRow(row);
    setUpdateModalVisible(true);
  };

  const columns: ProColumns<API.AbRule>[] = [
    {
      title: t('Name'),
      search: false,
      dataIndex: 'name',
      render: (text, record) => {
        return getNameOfRule(record);
      }
    },
    {
      title: plain('Type'),
      search: false,
      dataIndex: 'level',
      tooltip: plain("rule_priority_tip", "Priority: User > Group > Everyone"),
      render: (text, record) => {
        return getTypeOfRule(record);
      }
    },
    {
      title: plain('Permission'),
      search: false,
      dataIndex: 'rule',
      tooltip: plain("permission_tip", permissionTooltip),
      render: (text, record) => {
        switch (record.rule) {
          case ShareRule.READ:
            return plain('Read-only');
          case ShareRule.READ_WRITE:
            return plain('Read/Write');
          case ShareRule.FULL_CONTROL:
            return plain('Full Control');
        }
        return record.rule;
      }
    },
    {
      title: t('Action'),
      search: false,
      width: 120,
      render: (text, record) => (
        <Fragment>
          <a onClick={() => handleEdit(record)}> {t('Edit')}</a>
          <Divider type="vertical" />
          <DeleteConfirm onClick={() => handleDelete([record])}>
            <a style={{ color: "red" }}>{plain('Delete')}</a>
          </DeleteConfirm>
        </Fragment>
      ),
    },
  ];

  const onClose = () => {
    props.setVisible(false);
    setSelectedRows([]);
  }

  return (
    <Modal
      open={props.visible}
      width="90vw"
      style={{
        overflowX: "hidden",
      }}
      onCancel={onClose}
      destroyOnClose={true}
      title={<span>{plain('Share')} <Typography.Text mark>{props.profile.name}</Typography.Text></span>}
      closeIcon={<CloseOutlined />}
      footer={
        selectedRowsState.length > 0 ?
          <Row justify="center" gutter={16}>
            <Col>
              <DeleteConfirm
                onClick={async () => {
                  await handleDelete(selectedRowsState);
                  setSelectedRows([]);
                }}
              >
                <Button type="primary" >{t('Delete')}</Button>
              </DeleteConfirm>
            </Col >
          </Row>
          : null
      }
    >
      <ProTable<API.AbRule, API.PageParams & { ab: string } >
        headerTitle={plain('Permissions')}
        actionRef={actionRef}
        columnsState={{ persistenceType: 'localStorage', persistenceKey: 'ab_share_rule_columns_state' }}
        rowKey="guid"
        search={false}
        params={{
          ab: props.profile.guid,
        }}
        // dataSource={allRules}
        request={getAbRulesInPage}
        columns={columns}
        toolBarRender={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              setCreateModalVisible(true);
            }}
          >
            <PlusOutlined /> {t('Add')}
          </Button>,
        ]}
        rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
          },
        }}
        pagination={getTablePaginationConfig("ab_share_rule")}
      />
      <RuleForm
        isCreate={true}
        visible={createModalVisible}
        setModalVisible={setCreateModalVisible}
        actionRef={actionRef}
        initialValues={undefined}
        profile={props.profile}
      >
      </RuleForm>
      <RuleForm
        isCreate={false}
        visible={updateModalVisible}
        setModalVisible={setUpdateModalVisible}
        actionRef={actionRef}
        initialValues={currentRow}
        profile={props.profile}
      >
      </RuleForm>
    </Modal >

  );
};

export default RuleTableModal;


