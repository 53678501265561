import React, { useEffect, useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Card, Alert, Typography, Spin, Descriptions, theme, Button } from 'antd';
import { useIntl } from '@umijs/max';
import { t, urlRenewUpgradeLicense, urlValidator } from '@/global';
import { useModel } from '@umijs/max';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { encode } from 'js-base64';
import { QRCodeCanvas } from 'qrcode.react';
import styles from './Welcome.less';
import { getCfg } from '@/services/api';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
const { useToken } = theme;

type CfgType = Record<'port' | 'key', string>;
type ValType = Record<'host' | 'api', string>;

export type ServerProps = {
  host: string;
  key: string;
  api: string;
}

export const serverPropsFromCfg = (cfg: CfgType): ServerProps => {
  let port = cfg.port || '';
  if (port) port = ':' + port;
  const port2 = cfg.port ? parseInt(cfg.port) - 2 : 21114;
  return {
    host: window.location.hostname + port,
    key: cfg.key,
    // TODO https consider [window.location.origin]
    api: 'http://' + window.location.hostname + ':' + port2
  };
}

const Welcome: React.FC = () => {
  const [windowsExe, setWindowsExe] = useState<boolean>(false);
  const [cfg, setCfg] = useState<CfgType>({} as CfgType);
  const [values, setValues] = useState<ValType>({} as ValType);
  const [loading, setLoading] = useState<boolean>(false);
  const [latest, setLatest] = useState<string>('');
  const { initialState } = useModel('@@initialState');
  const is_admin = initialState?.user?.is_admin;
  const license = initialState?.license;
  const settingsList = initialState?.settings;
  const [_, setCurrentLicense] = useState("");
  const intl = useIntl();
  const { token } = useToken();

  const plain = (text: string, defaultMessage = '') => t(`pages.Welcome.${text}`, true, defaultMessage || text, intl) as string;

  useEffect(() => {
    if (settingsList) {
      const licenseSetting = settingsList.find(entry => entry.key === "LICENSE");
      if (licenseSetting) {
        setCurrentLicense(licenseSetting.value || '')
      }

      fetch(`https://api.github.com/repos/rustdesk/rustdesk-server-pro/releases/latest`)
        .then(response => response.json())
        .then(data => {
          const latest = data.tag_name;
          setLatest(latest);
          console.log(`Latest release tag: ${latest}`);
        })
        .catch(error => console.error(error));
    }
  }, [settingsList]);

  const toggleWindowsExe = async () => {
    setWindowsExe(!windowsExe);
    if (!windowsExe && !cfg.port) {
      setLoading(true);
      try {
        setCfg(await getCfg());
      } catch (e) { }
      setLoading(false);
    }
  };

  let initValues: ServerProps = { host: '', key: '', api: '' };
  let encoded = '';
  let exe = '';
  let qr = '';
  if (cfg.key) {
    initValues = serverPropsFromCfg(cfg);
    const tmp = { host: values.host || initValues.host, key: cfg.key } as any;
    if (values.api) tmp['api'] = values.api;
    encoded = encode(JSON.stringify(tmp), true).split('').reverse().join('');
    exe = 'rustdesk-licensed-' + encoded + '.exe';
    qr = 'config=' + encoded;
  }

  let welcome_msg = <span>{t('welcomeTitle', true, 'Welcome to RustDesk Server Pro') + (initialState?.version ? ` ${initialState.version}` : '')}
    {latest && initialState?.version && latest !== initialState?.version &&
      <span style={{ display: 'inline-block', paddingLeft: 12, fontSize: 13 }}><a target="_blank" href="https://github.com/rustdesk/rustdesk-server-pro/releases/latest">{t('new version available', true) + `: ${latest}`}</a></span>
    }
  </span>;


  const titleStyle = { color: token.colorText };
  let ltype = license?.type || '-';
  // old basic is personal plan
  if (ltype == 'Basic' && (license?.max_users || 0) < 10) {
    ltype = 'Personal';
  }

  return (
    <PageContainer>
      <Card>
        <Alert
          message={welcome_msg}
          type="success"
          showIcon
          banner
          style={{
            marginBottom: 24,
          }}
        />
        <Descriptions bordered>
          <Descriptions.Item label={t('User')}>{initialState?.user?.name}</Descriptions.Item>
          <Descriptions.Item label={t('Group')}>
            {initialState?.group?.name}
          </Descriptions.Item>
          {initialState?.user?.is_admin ? (
            <>
              {license?.type && <Descriptions.Item label={t('License Type')}>
                {plain(ltype)}
              </Descriptions.Item>}
              {license?.max_users && <Descriptions.Item label={plain('Max Users')}>{license?.max_users || '-'}</Descriptions.Item>}
              <Descriptions.Item label={plain('Max Devices')}>{license?.max_peers || '-'}</Descriptions.Item>
              {(license?.max_conns || 0) > 0 && <Descriptions.Item label={plain('Concurrent Connections')}>{license?.max_conns}</Descriptions.Item>}
              <Descriptions.Item label={plain('License Expire Time')}>
                {license?.expiry ? new Date(license?.expiry * 1000).toLocaleString() : '-'}
              </Descriptions.Item>
            </>
          ) : undefined}
        </Descriptions>
        <br />

        <br />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {is_admin ? (
            <Typography.Text strong>
              <a onClick={toggleWindowsExe}>
                {t('Config & QR code && Windows EXE')}
                {!windowsExe ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
              </a>
            </Typography.Text>
          ) : <div />}
          <Button style={{ float: 'right' }} type='primary' onClick={() => window.open(urlRenewUpgradeLicense, '_blank')}>
            {t('Renew / Upgrade License')}
          </Button>
        </div>
        {windowsExe &&
          (loading ? (
            <div style={{ width: '100%', textAlign: "center" }}><Spin /></div>
          ) : (
            <pre className={styles.pre} style={{ boxShadow: token.boxShadow }}>
              <code>
                <ProForm
                  layout="horizontal"
                  labelAlign="right"
                  labelCol={{ span: 2 }}
                  onFinish={async (value: any) => setValues(value)}
                  submitter={{
                    resetButtonProps: {
                      style: {
                        display: 'none',
                      },
                    },
                    submitButtonProps: {
                      children: t('Update'),
                    },
                  }}
                  autoComplete="off"
                >
                  <ProFormText
                    fieldProps={{ autoComplete: 'off' }}
                    width="md"
                    name="host"
                    label={t('Host', true)}
                    rules={[
                      {
                        whitespace: true,
                      },
                    ]}
                    placeholder={initValues.host}
                  />
                  <ProForm.Item label="Key">
                    <Typography.Text copyable code={true}>
                      {initValues.key}
                    </Typography.Text>
                  </ProForm.Item>
                  <ProFormText
                    fieldProps={{ autoComplete: 'off' }}
                    width="md"
                    name="api"
                    label={t('API', true)}
                    placeholder={initValues.api}
                    rules={[
                      {
                        validator: urlValidator
                      },
                    ]}
                  />
                </ProForm>
                {encoded && (
                  <>
                    <br />
                    <Title level={4} style={titleStyle}>Config</Title>
                    <pre>
                      <Typography.Text
                        copyable
                        code={true}
                        style={{ padding: '1em 0', display: 'inline-block' }}
                      >
                        {encoded}
                      </Typography.Text>
                    </pre>
                    <Title level={4} style={titleStyle}>Windows EXE</Title>
                    <pre>
                      <Typography.Text
                        copyable
                        code={true}
                        style={{ padding: '1em 0', display: 'inline-block' }}
                      >
                        {exe}
                      </Typography.Text>
                    </pre>
                    <Title level={4} style={titleStyle}>Mobile QR Code</Title>
                    <div style={{ background: 'white', padding: 8, borderRadius: 8, display: 'inline-block' }}><QRCodeCanvas value={qr} size={192} bgColor='white' includeMargin /></div>
                  </>
                )}
              </code>
            </pre>
          ))}
      </Card>
    </PageContainer >
  );
};

export default Welcome;
