import { API } from "@/services/typings";
import { ActionType } from "@ant-design/pro-table";
import { Radio, RadioChangeEvent } from "antd";
import { useState } from "react";

export const getLocalSortState = (sortStateKey: string): API.SortState => {
    const sortState = localStorage.getItem(sortStateKey);
    return sortState ? JSON.parse(sortState) : {};
}

export const setLocalSortState = (sortStateKey: string, sortState: API.SortState) => {
    localStorage.setItem(sortStateKey, JSON.stringify(sortState));
}

export const handleSortStateChange = (
    sorter: any,
    actionRef: React.MutableRefObject<ActionType | undefined>,
    sortStateKey: string,
    setSortState: React.Dispatch<any>,
) => {
    const sortState: API.SortState = Array.isArray(sorter) ? {} : {
        columnKey: sorter.columnKey,
        order: sorter.order,
    };
    setLocalSortState(sortStateKey, sortState);
    setSortState?.(sortState);
    actionRef.current?.reload();
}

export const TableSorterTooltip: React.FC<{
    actionRef: React.MutableRefObject<ActionType | undefined>,
    idSortKey: string,
    sortStateKey: string,
    options: { value: string, label: string }[],
    setSortState: React.Dispatch<any>,
}> = (props) => {
    const [idSortKey, setIdSortKey] = useState<string>(
        localStorage.getItem(props.idSortKey) || props.options[0].value
    );

    const onChange = (e: RadioChangeEvent) => {
        setIdSortKey(e.target.value);
        localStorage.setItem(props.idSortKey, e.target.value);
        
        const sortState: API.SortState = {
            columnKey: e.target.value,
            order: "ascend",
        };
        setLocalSortState(props.sortStateKey, sortState);
        props.setSortState?.(sortState);

        props.actionRef?.current?.reload();
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Radio.Group
                onChange={onChange}
                value={idSortKey}
                options={props.options}
            />
        </div>
    )
}
