import { getTablePaginationConfig, t } from "@/global";
import { deleteSharedAbArray, sharedAbProfiles } from "@/services/api";
import { API } from "@/services/typings";
import { PlusOutlined } from "@ant-design/icons";
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table";
import { Link, useIntl } from "@umijs/max";
import { Button, Divider, Popconfirm, message } from "antd";
import { Fragment, useRef, useState } from "react";
import CreateOrUpdateForm from "./CreateOrUpdateForm";
import { FooterToolbar } from "@ant-design/pro-layout";
import RuleTable from "./RuleTable";
import { permissionTooltip, ShareRule } from "./RuleForm";
import { getLocalSortState, handleSortStateChange } from "@/components/Others/table_sort";

export const AB_PROFILE_SORT_STATE_KEY = 'ab_profile_sort_state';

const AddressBookTable: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [ruleModalVisible, setRuleModalVisible] = useState<boolean>(false);
  const [selectedRowsState, setSelectedRows] = useState<API.SharedAbProfile[]>([]);
  const [currentRow, setCurrentRow] = useState<API.SharedAbProfile>();
  const [sortState, setSortState] = useState<API.SortState>(() => getLocalSortState(AB_PROFILE_SORT_STATE_KEY));
  const intl = useIntl();

  const plain = (text: string, defaultMessage = '') => t(`pages.AddressBook.${text}`, true, defaultMessage || text, intl) as string;

  const DeleteConfirm = (props: { children: any; onClick: () => void }) => (
    <Popconfirm
      title={t('deleteConfirm', true, 'Are you sure to delete?')}
      onConfirm={() => props.onClick()}
      okText="Yes"
      cancelText="No"
    >
      {props.children}
    </Popconfirm>
  );

  const handleDelete = async (rows: API.SharedAbProfile[]) => {
    const hide = message.loading(t('Deleting'));
    try {
      await deleteSharedAbArray({
        data: rows.map((x) => x.guid),
      });
      hide();

      message.success(t('Deletetion is successful'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Deletetion failed, please try again!'),
      );
      return false;
    }
  };

  const handleEdit = async (row: API.SharedAbProfile) => {
    setCurrentRow(row);
    setUpdateModalVisible(true);
  };

  const handleShare = async (row: API.SharedAbProfile) => {
    setCurrentRow(row);
    setRuleModalVisible(true);
  };

  const columns: ProColumns<API.SharedAbProfile>[] = [
    {
      title: t('Name'),
      dataIndex: 'name',
      render: (text, record) => (
        <Link to={`/ab/${record.guid}`}>{text}</Link>
      ),
      key: 'a_name',
      sorter: true,
      sortOrder: sortState.columnKey === 'a_name' ? sortState.order : undefined,
      showSorterTooltip: false,
      hideInSearch: true,
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      hideInTable: true,
    },
    {
      title: plain('Owner'),
      dataIndex: 'owner',
      hideInSearch: true,
      key: 'u_name',
      sorter: true,
      sortOrder: sortState.columnKey === 'u_name' ? sortState.order : undefined,
      showSorterTooltip: false,
    },
    {
      title: plain('My Permission'),
      dataIndex: 'rule',
      hideInSearch: true,
      tooltip: plain("permission_tip", permissionTooltip),
      render: (text, record) => {
        switch (record.rule) {
          case ShareRule.READ:
            return plain('Read-only');
          case ShareRule.READ_WRITE:
            return plain('Read/Write');
          case ShareRule.FULL_CONTROL:
            return plain('Full Control');
        }
        return record.rule;
      }
    },
    {
      title: t('Note'),
      dataIndex: 'note',
      hideInSearch: true,
      key: 'a_note',
      sorter: true,
      sortOrder: sortState.columnKey === 'a_note' ? sortState.order : undefined,
      showSorterTooltip: false,
    },
    {
      title: t('Action'),
      search: false,
      width: 240,
      render: (text, record) => (
        record.rule === ShareRule.FULL_CONTROL &&
        <Fragment>
          <a onClick={() => handleEdit(record)}>{t('Edit')}</a>
          <Divider type="vertical" />
          <a onClick={() => handleShare(record)}>{plain('Share')}</a>
          <Divider type="vertical" />
          <DeleteConfirm onClick={() => { handleDelete([record]); actionRef.current?.reloadAndRest?.(); }}><a style={{ color: 'red' }}>{plain('Delete')}</a></DeleteConfirm>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <ProTable<API.SharedAbProfile, API.PageParams>
        headerTitle={plain('Address Books')}
        actionRef={actionRef}
        columnsState={{ persistenceType: 'localStorage', persistenceKey: 'ab_profile_columns_state' }}
        rowKey="guid"
        search={{
          labelWidth: 0,
        }}
        request={sharedAbProfiles}
        columns={columns}
        toolBarRender={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              setCreateModalVisible(true);
            }}
          >
            <PlusOutlined /> {t('Create')}
          </Button>,
        ]}
        rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
          },
        }}
        pagination={getTablePaginationConfig("address_book_profiles")}
        onChange={(_, __, sorter) => handleSortStateChange(sorter, actionRef, AB_PROFILE_SORT_STATE_KEY, setSortState)}
      />
      {selectedRowsState?.length > 0 && (
        <FooterToolbar
          extra={
            <div>
              {t('Chosen')} <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>{' '}
              {t('Items')}
            </div>
          }
        >
          <DeleteConfirm
            onClick={async () => {
              await handleDelete(selectedRowsState);
              setSelectedRows([]);
              actionRef.current?.reloadAndRest?.();
            }}
          >
            <Button>{plain('Delete')}</Button>
          </DeleteConfirm>
        </FooterToolbar>
      )}
      <CreateOrUpdateForm
        isCreate={true}
        visible={createModalVisible}
        setModalVisible={setCreateModalVisible}
        actionRef={actionRef}
        initialValues={undefined}
      ></CreateOrUpdateForm>
      <CreateOrUpdateForm
        isCreate={false}
        visible={updateModalVisible}
        setModalVisible={setUpdateModalVisible}
        actionRef={actionRef}
        initialValues={currentRow}
      ></CreateOrUpdateForm>
      {currentRow && <RuleTable visible={ruleModalVisible} setVisible={setRuleModalVisible} profile={currentRow}></RuleTable>}
    </Fragment>

  );
};

export default AddressBookTable;
