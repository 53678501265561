import ProForm, { ProFormCheckbox, ProFormItem, ProFormRadio, ProFormText, ProFormSelect, ProFormTextArea, ProFormUploadButton } from "@ant-design/pro-form";
import type { FormInstance, GlobalToken, InputRef, UploadFile } from "antd";
import { Form, Switch, Tooltip, message, Image as AntdImage, Button, Typography, Spin, Col, Row, Card } from "antd";
import styles from './Details.less';
import type { API } from "@/services/typings";
import { names as fetchNames, getCfg, getCustomClient, newCustomClient, updateCustomClient } from "@/services/api";
import { t, urlValidator } from "@/global";
import { useEffect, useRef, useState } from "react";
import type { ConfigItem, CustomClientPageProps, FuncFormatMessage, Pagination } from "..";
import { CustomClientPage, OptionKeys, getDelete, gotoPage, gotoTablePage, isFixedOption, isIgnored, os2Desc, os2Icon, typeConfigValues } from "..";
import { CustomClientOs, CustomClientType, options } from "..";
import { Space } from "antd/lib";
import ProCard from "@ant-design/pro-card";
import type { ServerProps } from "@/pages/Welcome";
import { serverPropsFromCfg } from "@/pages/Welcome";
import type { Rule } from "antd/lib/form";
import type { ActionType } from "@ant-design/pro-table";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { UserPassword } from "@/pages/UserList";

const getHidableSwitch = (label: React.ReactNode | string, show: boolean, onChange: (v: boolean) => void, disabled: boolean) => <>
  <div style={{ display: 'flex', marginBottom: show ? '12px' : '24px', width: '172px', justifyContent: 'space-between' }}>
    <div>
      {label}
    </div>
    <Switch
      defaultValue={show}
      onChange={onChange}
      disabled={disabled}
    />
  </div>
</>;
const getHidableComponent = (show: boolean, content: React.ReactNode) => <>
  <ProFormItem style={{ display: show ? 'block' : 'none' }}>
    <div className={styles.hidableComponent}>
      {content}
    </div>
  </ProFormItem>
</>;

export type OsCardProps = {
  token: GlobalToken;
  page: CustomClientPage;
  supportedOs: CustomClientOs[];
  os: CustomClientOs;
  setOs: React.Dispatch<React.SetStateAction<CustomClientOs>>;
  plain: FuncFormatMessage;
}

const OsCard: React.FC<OsCardProps> = ({ token, page, supportedOs, os, setOs, plain }) => {
  const selectable = page != CustomClientPage.PageView && page != CustomClientPage.PageEdit;

  if (supportedOs.length <= 1) {
    return undefined;
  }

  const opts = [
    CustomClientOs.Windows,
    CustomClientOs.MacOS,
    CustomClientOs.Linux,
    CustomClientOs.Android,
    CustomClientOs.IOS,
  ];

  const cardContent = (opt: CustomClientOs) => <>
    <div className={styles.cardContainer}>
      <div className={styles.icon}>
        {os2Icon(opt, { fontSize: '24px' })}
      </div>
      <div className={styles.os}>
        {os2Desc(opt, plain)}
      </div>
    </div>
  </>;

  const cards = opts.filter((v) => selectable ? supportedOs.includes(v) : v == os).map((v) => {
    return <Col key={v} span={4}>
      <Card
        bordered
        hoverable={selectable}
        className={styles.osCard}
        onClick={!selectable ? undefined : () => setOs(v)}
        style={{
          color: os == v ? token.colorPrimary : undefined,
          borderColor: os == v ? token.colorPrimary : undefined
        }}
        bodyStyle={{
          padding: '8px',
        }}
      >
        {cardContent(v)}
      </Card>
    </Col>;
  });
  if (cards.length == 0) {
    return undefined;
  }
  while (cards.length < 4) {
    cards.push(<Col key='empty' span={4} />);
  }

  return <Row justify='space-between'> {cards} </Row>
}

export type GeneralCardProps = {
  token: GlobalToken;
  page: CustomClientPage;
  currentRow?: API.CustomClient;
  connType: number;
  setConnType: React.Dispatch<React.SetStateAction<number>>;
  supportedOs: CustomClientOs[];
  os: CustomClientOs;
  usedNames: string[];
  plain: FuncFormatMessage;
  form: FormInstance<any>;
}

const getOptionKey = (k: string) => 'options.' + k;

const GeneralCard: React.FC<GeneralCardProps> = ({
  token, page, currentRow, connType, setConnType, supportedOs, os, usedNames, plain, form
}) => {
  const nameInputRef = useRef<InputRef>(null);
  const appNameInputRef = useRef<InputRef>(null);
  const disabled = page == CustomClientPage.PageView;
  const isSupportedOsOnly = supportedOs.length == 1;
  const [isDisabledAccount, setIsDisabledAccount] = useState<boolean>(
    currentRow?.info.options[OptionKeys.DisableAccount] ?? (
      options.find(e => e.key == OptionKeys.DisableAccount)?.default ?? false)
  );

  useEffect(() => {
    if (page == CustomClientPage.PageEdit) {
      appNameInputRef.current?.focus();
    } else {
      nameInputRef.current?.focus();
    }
  }, [nameInputRef, appNameInputRef, page]);

  const makeOptions = () => {
    const getOptionChecked = (opt: ConfigItem<boolean>) => currentRow?.info.options[opt.key] ?? opt.default;

    return <Row>
      {options.map((opt) => {
        if (os != CustomClientOs.Windows) {
          if (opt.key == OptionKeys.DisableInstallation) {
            return undefined;
          }
        }
        if (isIgnored(connType, opt.key) || isFixedOption(connType, opt.key) != undefined) {
          return undefined;
        }

        return <Col span={24} key={opt.key}>
          <ProFormCheckbox
            fieldProps={{
              defaultChecked: getOptionChecked(opt),
              onChange: (e: any) => {
                if (opt.key == OptionKeys.DisableAccount) {
                  const isChecked = e.target.checked;
                  setIsDisabledAccount(isChecked);
                  if (isChecked) {
                    form.setFieldsValue({ [getOptionKey(OptionKeys.DisableAB)]: true });
                  }
                }
              }
            }}
            name={[getOptionKey(opt.key)]}
            disabled={disabled || (opt.key == OptionKeys.DisableAB && isDisabledAccount)}
            noStyle={true}
          >
            <span style={{ fontWeight: opt.emphasized ? '600' : undefined }}>
              {plain(opt.label, undefined, opt.formatIdPrefix)}
            </span>
            {
              opt.desc && <Tooltip title={plain(opt.desc, undefined, opt.formatIdPrefix)}>
                <QuestionCircleOutlined style={{ marginLeft: 4 }} />
              </Tooltip>
            }
          </ProFormCheckbox>
        </Col>;
      })}
    </Row>;
  }

  const validatorName = (rule: any, value: string) => {
    if (!value || value.length < 4) {
      return Promise.resolve();
    }
    if (value.trim().length < 4) {
      return Promise.reject(plain('Name must contain at least 4 characters'));
    }
    if (page == CustomClientPage.PageDup || currentRow?.name != value.trim()) {
      if (usedNames.includes(value.trim())) {
        return Promise.reject(plain('Name already exists'));
      }
    }
    return Promise.resolve();
  }

  const getPackageLabel = () => {
    switch (os) {
      case CustomClientOs.MacOS:
        return plain('Bundle identifier');
      case CustomClientOs.Android:
        return plain('Package name');
      case CustomClientOs.IOS:
        return plain('Bundle identifier');
      default:
        return plain('Application name');
    }
  }

  const validatePackageName = (rule: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    const fds = value.split('.');
    if (value.toLowerCase().indexOf('rustdesk') != -1) {
      return Promise.reject(plain('The name cannot contain "rustdesk"'));
    }
    if (fds.length != 3) {
      return Promise.reject(plain('Invalid') + ' ' + getPackageLabel() + ', it should be something like, com.yourcompany.yourapp');
    }
    for (let i = 0; i < fds.length; i++) {
      if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(fds[i])) {
        return Promise.reject(plain('Invalid') + ' ' + getPackageLabel());
      }
    }
    return Promise.resolve();
  }

  const validatorAppName = (rule: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    if (!/^[a-zA-Z][a-zA-Z0-9-]*$/.test(value)) {
      return Promise.reject(plain('Application name must start with a letter and contain only letters, numbers, hyphens.'));
    }
    return Promise.resolve();
  }

  const labelAppName = () => {
    return <Space>
      {isSupportedOsOnly && os2Icon(os, { color: token.colorPrimary, fontSize: '18px' })}
      {plain('Application name')}
    </Space>
  }

  const typeOptionLabel = (ct: CustomClientType) => {
    const v = typeConfigValues[ct];
    return <Tooltip title={plain(v.desc)}>
      {plain(v.label, undefined, v.formatIdPrefix)}
    </Tooltip>;
  }

  return <>
    <ProFormText
      name='name'
      label={plain('Name of the configuration')}
      width={'md'}
      rules={[{ required: true, min: 4 }, { validator: validatorName }]}
      initialValue={page == CustomClientPage.PageDup ? undefined : currentRow?.name}
      disabled={disabled}
      fieldProps={{ ref: nameInputRef }}
    />
    <ProFormText
      name={['info', 'appName']}
      width={'md'}
      label={labelAppName()}
      rules={[{ required: true, message: plain('Please enter the app name') }, { validator: validatorAppName }]}
      initialValue={currentRow?.info?.appName}
      disabled={disabled}
      tooltip={plain('app_name_desc_tip')}
      fieldProps={{ ref: appNameInputRef }}
    />
    {(os == CustomClientOs.MacOS || os == CustomClientOs.Linux) && <ProFormSelect
      name={['info', 'arch']}
      width={'md'}
      label={plain("CPU Architecture")}
      rules={[{ required: true }]}
      initialValue={currentRow?.info?.arch || (os == CustomClientOs.MacOS ? 'arm64' : 'x64')}
      disabled={disabled}
      options={[
        { value: 'x64' },
        { value: 'arm64' },
      ]}
    />}
    {os == CustomClientOs.Linux && <ProFormSelect
      name={['info', 'distro']}
      width={'md'}
      label={plain("Distribution")}
      rules={[{ required: true }]}
      initialValue={currentRow?.info?.distro}
      disabled={disabled}
      options={[
        { value: 'debian', label: 'Debian/Ubuntu' },
        { value: 'fedora', label: 'Fedora/RHEL' },
        { value: 'arch', label: 'Arch/Manjora' },
        { value: 'suse', label: 'openSUSE' },
        { value: 'appimage', label: 'AppImage' },
        { value: 'flatpak', label: 'FlatPak' },
      ]}
    />}
    {os == CustomClientOs.Windows && <><ProFormCheckbox
      name={['info', 'msi']}
      noStyle={true}
      initialValue={currentRow?.info?.msi ?? false}
    >
      <span>
        {plain("MSI installer")}
      </span>
      {
        <Tooltip title="Generate a msi installer file rather than exe file">
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      }
    </ProFormCheckbox>
      <div style={{ height: '12px' }} /></>}
    {os != CustomClientOs.Windows && os != CustomClientOs.Linux && <ProFormText
      name={['info', 'packageName']}
      width={'md'}
      label={getPackageLabel()}
      rules={[{ required: true, message: plain('Please enter') + getPackageLabel() }, { validator: validatePackageName }]}
      initialValue={currentRow?.info?.packageName}
      disabled={disabled}
      tooltip={plain('e.g. com.yourcompany.yourapp')}
      placeholder="e.g. com.yourcompany.yourapp"
    />}
    <ProFormRadio.Group
      name={['info', 'connType']}
      label={plain('Connection type')}
      layout='horizontal'
      initialValue={connType}
      disabled={disabled}
      options={[
        CustomClientType.IncomingOutgoing,
        CustomClientType.Incoming,
        CustomClientType.Outgoing
      ].map((k) => {
        return { label: typeOptionLabel(k), value: k };
      })}
      rules={[{ required: true }]}
      // @ts-ignore
      onChange={(e: any) => setConnType(e.target.value)}
    />
    <ProFormItem
      label={plain('Options')}
    >
      <div style={{ height: '12px' }} />
      {makeOptions()}
    </ProFormItem>
    <ProFormTextArea
      name="note"
      label={plain('Note')}
      placeholder={disabled ? '' : plain('Some note about this configuration')}
      initialValue={currentRow?.note}
      disabled={disabled}
    />
  </>;
}

type CustomServerCardProps = {
  page: CustomClientPage;
  currentRow?: API.CustomClient;
  serverProps: ServerProps;
  plain: FuncFormatMessage;
}

const CustomServerCard: React.FC<CustomServerCardProps> = ({ page, currentRow, serverProps, plain }) => {
  const disabled = page == CustomClientPage.PageView;

  const serverComponentItem = (name: string, tooltip: string, placeholder: string, rules: Rule[]) => <>
    <div className={styles.customServerItem}>
      <Tooltip title={tooltip}>
        <div className={styles.label}>
          {plain(name) + ' : '}
        </div>
      </Tooltip>
      <ProFormText
        fieldProps={{ autoComplete: 'off' }}
        name={['server', name.toLowerCase()]}
        width={'md'}
        initialValue={currentRow?.info?.server?.[name.toLowerCase()] ?? ''}
        placeholder={disabled ? '' : placeholder}
        disabled={disabled}
        rules={rules}
        filedConfig={{
          style: { marginBottom: '0px' }
        }}
        validateTrigger={['onBlur']}
      />
    </div>
  </>;

  return <>
    <Space direction='vertical'>
      {serverComponentItem('Host', plain('IP[:port] or domain of the server'), serverProps.host, [{ whitespace: true }])}
      <div className={styles.customServerItem}>
        <Tooltip title={plain('The key of for API and connections.')}>
          <div className={styles.label}>
            {plain('Key') + ' : '}
          </div>
        </Tooltip>
        <Typography.Text copyable code={true}>
          {currentRow?.info?.server?.key ?? serverProps.key}
        </Typography.Text>
      </div>
      {serverComponentItem('API', plain('The API URL prefix. schema://authority'), serverProps.api, [{ validator: urlValidator }])}
    </Space>
  </>;
}

type VisualCardProps = {
  page: CustomClientPage;
  currentRow?: API.CustomClient;
  appIcon: HTMLImageElement | undefined;
  setAppIcon: React.Dispatch<React.SetStateAction<HTMLImageElement | undefined>>;
  logo: HTMLImageElement | undefined;
  setLogo: React.Dispatch<React.SetStateAction<HTMLImageElement | undefined>>;
  plain: FuncFormatMessage;
  form: FormInstance<any>;
  os: CustomClientOs;
}

const VisualCard: React.FC<VisualCardProps> = ({
  page, currentRow, appIcon, setAppIcon, logo, setLogo, plain, form, os
}) => {
  const [appIconErr, setAppIconErr] = useState<string | undefined>(undefined);
  const [logoErr, setLogoErr] = useState<string | undefined>(undefined);

  const pngValidator = (
    file: any,
    setImage: React.Dispatch<React.SetStateAction<HTMLImageElement | undefined>>,
    setErr: (err: string | undefined) => void,
    hsize: number,
    isSquare: boolean,
  ) => {
    const isPng = file.type === 'image/png';
    const isSizeInRange = file.size <= 5 * 1024 * 1024;

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (width != height && isSquare) {
          setErr(plain('The image must be square'));
        }
        if (height < hsize) {
          setErr(plain(`The image height size must be at least ${hsize} pixels`));
        }
        setImage(img);
      };
      if (e.target) {
        img.src = e.target.result as string;
      } else {
        setErr(plain('Failed to load the image'));
      }
    };
    reader.readAsDataURL(file.originFileObj);

    if (!isPng) {
      return Promise.reject(plain('Only PNG files are allowed'));
    }

    if (!isSizeInRange) {
      return Promise.reject(plain('The file size must be less than 5MB'));
    }

    return Promise.resolve();
  }

  const appIconValidator = (rule: any, files: any[]) => {
    if (!files || files.length === 0) {
      setAppIconErr(undefined);
      return Promise.resolve();
    }
    if (appIconErr) {
      return Promise.reject(appIconErr);
    }
    if (files[0].url != undefined) {
      return Promise.resolve();
    }
    return pngValidator(
      files[0],
      setAppIcon, (err: string | undefined) => {
        setAppIconErr(err);
        form.validateFields(['icon']);
      },
      512, true);
  }

  const logoValidator = (rule: any, files: any[]) => {
    if (!files || files.length === 0) {
      setLogo(undefined);
      return Promise.resolve();
    }
    if (logoErr) {
      return Promise.reject(logoErr);
    }
    if (files[0].url != undefined) {
      return Promise.resolve();
    }
    return pngValidator(
      files[0],
      setLogo,
      (err: string | undefined) => {
        setLogoErr(err);
        form.validateFields(['logo']);
      },
      40, false);
  }

  const imageList = (name: string, data: string | undefined): UploadFile[] => {
    if (data == undefined) {
      return []
    }
    return [
      {
        uid: '0',
        name: `${name}.png`,
        url: `data:image/png;base64,${data}`,
        thumbUrl: `data:image/png;base64,${data}`,
        status: 'done',
      }
    ];
  };

  const getImage = (data: string | undefined, alt: string | undefined) => {
    if (data == undefined) {
      return undefined;
    }
    let src = data;
    if (!src.startsWith('data:image')) {
      src = `data:image/png;base64,${data}`;
    }
    return <AntdImage src={src} alt={alt} />;
  }

  const getImageStyle = (img: HTMLImageElement | undefined) => {
    const width = img?.width ?? 32;
    const height = img?.height ?? 32;
    const max = Math.max(width, height);
    if (max >= 100) {
      return {
        width: (100 * width / max).toFixed(0) + 'px',
        height: (100 * height / max).toFixed(0) + 'px',
      }
    } else {
      return {
        width: `${width}px`,
        height: `${height}px`,
      }
    }
  }

  const getItemRender = (img: HTMLImageElement | undefined, alt: string | undefined) => {
    return (originNode: React.ReactNode, file: UploadFile, fileList: UploadFile[], { remove }: {
      download: () => void,
      preview: () => void,
      remove: () => void,
    }) => {
      return <>
        <ProCard bordered style={{ width: '100%', borderRadius: 8 }} >
          <div style={{ display: 'flex', justifyContent: 'space-between', 'alignItems': 'center' }}>
            <div style={{ display: 'flex', 'alignItems': 'center' }}>
              <div style={getImageStyle(img)}>
                {getImage(img?.currentSrc, alt)}
              </div>
              <div style={{ width: '6px' }} />
              {file.fileName ?? file.name}
            </div>

            <Button type='text' size='small' onClick={remove}>
              <DeleteOutlined />
            </Button>
          </div>
        </ProCard>
      </>;
    };
  }

  const uploadComponent = <>
    <ProFormUploadButton
      name="icon"
      label={plain('Application icon')}
      tooltip={plain('app_icon_desc_tip')}
      max={1}
      initialValue={imageList('icon', currentRow?.info.icon)}
      title={plain('Click or drag to upload')}
      rules={[{ validator: appIconValidator }]}
      fieldProps={{
        accept: '.png',
        onChange: () => setAppIconErr(undefined),
        onRemove: () => setAppIcon(undefined),
        itemRender: getItemRender(appIcon, 'icon'),
      }}
    />
    {os == CustomClientOs.MacOS && <><ProFormCheckbox
      name={["info", "noPadding"]}
      noStyle={true}
      initialValue={currentRow?.info.noPadding ?? false}
    >
      <span>
        {plain("Disable auto padding of application icon")}
      </span>
      {
        <Tooltip title={<span><a target="_blank" rel="noreferrer" href="https://stackoverflow.com/questions/71118094/why-is-my-app-icon-bigger-than-the-others-in-the-macos-dock">Here</a> talks about why a padding is needed, if our auto padding does not work for you, please add padding yourself.</span>}>
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      }
    </ProFormCheckbox>
      <div style={{ height: '12px' }} /></>}
    <ProFormUploadButton
      name="logo"
      label={plain('Logo')}
      tooltip={plain('logo_desc_tip')}
      max={1}
      initialValue={imageList('logo', currentRow?.info.logo)}
      title={plain('Click or drag to upload')}
      fieldProps={{
        accept: '.png',
        onChange: () => setLogoErr(undefined),
        onRemove: () => setLogo(undefined),
        itemRender: getItemRender(logo, 'logo'),
      }}
      rules={[{ validator: logoValidator }]}
    />
  </>;

  const viewComponent = <div className={styles.visualContainer}>
    <Space direction='vertical'>
      <div className={styles.visualImage}>
        <div className={styles.label}>
          {plain('Icon') + ' : '}
        </div>
        {
          currentRow?.info?.icon != undefined && <div className={styles.image} style={getImageStyle(appIcon)}>
            {getImage(currentRow?.info?.icon, 'icon')}
          </div>
        }
      </div>
      <div className={styles.visualImage}>
        <div className={styles.label}>
          {plain('Logo') + ' : '}
        </div>
        {
          currentRow?.info?.logo != undefined && <div className={styles.image} style={getImageStyle(logo)}>
            {getImage(currentRow?.info?.logo, 'logo')}
          </div>
        }
      </div>
    </Space>
  </div>;

  return page == CustomClientPage.PageView ? viewComponent : uploadComponent;
}

type SecurityCardProps = {
  page: CustomClientPage;
  currentRow?: API.CustomClient;
  presetPassword: boolean;
  setPresetPassword: React.Dispatch<React.SetStateAction<boolean>>;
  plain: (text: string, defaultMessage?: string) => string;
}

const SecurityCard: React.FC<SecurityCardProps> = ({
  page, currentRow,
  presetPassword, setPresetPassword,
  plain
}) => {
  const disabled = page == CustomClientPage.PageView;

  const presetPasswordComponent = <>
    <div style={{ marginBottom: '24px' }}>
      <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
        {plain('Note: ')}
      </span>
      <span>
        {plain('This password will be used as a default permanent password for all new clients. It can be changed by the client.')}
      </span>
    </div>
    <UserPassword required={presetPassword} initialValue={currentRow?.info.password} />
  </>;

  return <>
    {getHidableSwitch(plain('Preset password') + ' : ', presetPassword, setPresetPassword, disabled)}
    {getHidableComponent(
      presetPassword,
      disabled ?
        <ProFormText fieldProps={{ value: '****************' }} disabled={disabled} /> :
        presetPasswordComponent)
    }
  </>;
}

type AdvancedCardProps = {
  page: CustomClientPage;
  currentRow?: API.CustomClient;
  overrideSettings: boolean;
  setOverrideSettings: React.Dispatch<React.SetStateAction<boolean>>;
  defaultSettings: boolean;
  setDefaultSettings: React.Dispatch<React.SetStateAction<boolean>>;
  plain: (text: string, defaultMessage?: string) => string;
}

const AdvancedCard: React.FC<AdvancedCardProps> = ({
  page, currentRow,
  overrideSettings, setOverrideSettings,
  defaultSettings, setDefaultSettings,
  plain
}) => {
  const disabled = page == CustomClientPage.PageView;

  const settingsValidator = (rule: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    const settings = value.split('\n').map(e => e.trim()).filter(e => e.length > 0);
    for (const setting of settings) {
      const kv = setting.split('=');
      if (!setting.includes('=')) {
        return Promise.reject(plain('Invalid setting') + ': ' + setting);
      }
      if (kv[0].trim().length == 0) {
        return Promise.reject(plain('Invalid empty key') + ': ' + setting);
      }
    }
    return Promise.resolve();
  }

  const overrideSettingsLabel = <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{plain('override_settings_desc_tip')}</div>}>
    {plain('Override settings') + ' : '}
  </Tooltip>;
  const overrideSettingsComponent = <>
    <ProFormTextArea
      name={'overrideSettings'}
      placeholder={'key=value\nkey2=value2'}
      initialValue={currentRow?.info.overrideSettings?.join('\n')}
      disabled={disabled}
      rules={[{ validator: settingsValidator }]}
      validateTrigger={['onBlur']}
    />
  </>;

  const defaultSettingsLabel = <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{plain('default_settings_desc_tip')}</div>} >
    {plain('Default settings') + ' : '}
  </Tooltip>;
  const defaultSettingsComponent = <>
    <ProFormTextArea
      name={'defaultSettings'}
      placeholder={'key=value\nkey2=value2'}
      initialValue={currentRow?.info.defaultSettings?.join('\n')}
      disabled={disabled}
      rules={[{ validator: settingsValidator }]}
      validateTrigger={['onBlur']}
    />
  </>;
  return <>
    {getHidableSwitch(overrideSettingsLabel, overrideSettings, setOverrideSettings, disabled)}
    {getHidableComponent(overrideSettings, overrideSettingsComponent)}
    {getHidableSwitch(defaultSettingsLabel, defaultSettings, setDefaultSettings, disabled)}
    {getHidableComponent(defaultSettings, defaultSettingsComponent)}
  </>;
}

export type CustomClientDetailsProps = {
  token: GlobalToken;
  pageProps: CustomClientPageProps;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  plain: FuncFormatMessage;
  actionRef: React.MutableRefObject<ActionType | undefined>;
  setCurrentName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CustomClientDetails: React.FC<CustomClientDetailsProps> = ({
  token, pageProps, pagination, setPagination, plain, actionRef, setCurrentName
}) => {
  const [form] = Form.useForm();
  const { page, dataId } = pageProps;
  const [currentRow, setCurrentRow] = useState<API.CustomClient | undefined>(undefined);
  const [supportedOs] = useState<CustomClientOs[]>([CustomClientOs.Windows, CustomClientOs.MacOS, CustomClientOs.Linux, CustomClientOs.Android]);
  const [os, setOs] = useState<CustomClientOs>(currentRow?.os ?? supportedOs[0]);
  const [appIcon, setAppIcon] = useState<HTMLImageElement | undefined>(undefined);
  const [logo, setLogo] = useState<HTMLImageElement | undefined>(undefined);
  const [connType, setConnType] = useState<number>(currentRow?.info?.connType ?? (CustomClientType.IncomingOutgoing as number));
  const [presetPassword, setPresetPassword] = useState<boolean>(currentRow?.info.password != undefined);
  const [overrideSettings, setOverrideSettings] = useState<boolean>(currentRow?.info.overrideSettings != undefined);
  const [defaultSettings, setDefaultSettings] = useState<boolean>(currentRow?.info.defaultSettings != undefined);
  const [usedNames, setUsedNames] = useState<string[]>([]);
  const [serverProps, setServerProps] = useState<ServerProps>({
    host: currentRow?.info.server?.host ?? '',
    key: currentRow?.info.server?.key ?? '',
    api: currentRow?.info.server?.api ?? '',
  });
  const [loading, setLoading] = useState<boolean>(page != CustomClientPage.PageNew);

  const marginBlockStart = 8;

  useEffect(() => {
    if (page != CustomClientPage.PageNew && currentRow == undefined) {
      getCustomClient(dataId).then((resp) => {
        setCurrentRow(resp);
        setCurrentName(resp?.name);
        if (resp?.os) setOs(resp?.os)
        setConnType(resp?.info?.connType ?? (CustomClientType.IncomingOutgoing as number));
        setPresetPassword(resp?.info.password != undefined);
        setOverrideSettings(resp?.info.overrideSettings != undefined);
        setDefaultSettings(resp?.info.defaultSettings != undefined);

        const setImage = (imgBase64: string | undefined, set: React.Dispatch<React.SetStateAction<HTMLImageElement | undefined>>) => {
          if (imgBase64 == undefined) {
            return;
          }

          const img = new Image();
          img.onload = () => {
            set(img);
          };
          img.src = `data:image/png;base64,${imgBase64}`;
        }
        if (appIcon == undefined) {
          setImage(resp?.info.icon, setAppIcon);
        }
        if (logo == undefined) {
          setImage(resp?.info.logo, setLogo);
        }

        setLoading(false);
      }).catch((e) => {
        message.error(plain('Failed to get data') + ', ' + e);
        gotoTablePage();
      });
    }
  }, [appIcon, currentRow, currentRow?.info.icon, currentRow?.info.logo, dataId, logo, page, plain]);

  useEffect(() => {
    if (page != CustomClientPage.PageView && usedNames?.length == 0) {
      fetchNames({
        table: 'custom_client',
        pattern: '%',
        limit: 999,
      }).then((data) => {
        setUsedNames(data);
      });
    }
    if (currentRow == undefined) {
      getCfg().then((data) => {
        setServerProps(serverPropsFromCfg(data));
      });
    }
  }, [currentRow, form, page]);

  const fillPayload = (values: any, payload: API.NewCustomClient | API.UpdateCustomClient) => {
    // General card
    for (const opt of options) {
      if (isIgnored(connType, opt.key)) {
        continue;
      }

      const v = isFixedOption(connType, opt.key);
      if (v != undefined) {
        payload.info.options[opt.key] = v;
      } else {
        payload.info.options[opt.key] = values[getOptionKey(opt.key)] ??
          (currentRow?.info.options[opt.key] ?? opt.default);
      }
    }

    // Visual card
    if (appIcon) {
      payload.info.icon = appIcon.src.substring(appIcon.src.indexOf('base64,') + 7);
    }
    if (logo) {
      payload.info.logo = logo.src.substring(logo.src.indexOf('base64,') + 7);
    }

    // Custom server card
    const serverHost = values.server?.host ?? currentRow?.info.server?.host ?? '';
    const serverAPI = values.server?.api ?? currentRow?.info.server?.api ?? '';
    payload.info.server = {
      host: serverHost.length == 0 ? serverProps.host : serverHost,
      api: serverAPI.length == 0 ? serverProps.api : serverAPI,
      key: currentRow?.info.server?.key ?? serverProps.key,
    };

    // Security card
    if (presetPassword) {
      payload.info.password = values.password;
    }

    // Advanced card
    if (overrideSettings && values.overrideSettings) {
      const settings = (values.overrideSettings as string).split('\n').map(e => e.trim()).filter(e => e.length > 0);
      if (settings.length > 0) {
        payload.info.overrideSettings = settings;
      }
    }
    if (defaultSettings && values.defaultSettings) {
      const settings = (values.defaultSettings as string).split('\n').map(e => e.trim()).filter(e => e.length > 0);
      if (settings.length > 0) {
        payload.info.defaultSettings = settings;
      }
    }
    return payload;
  }

  const onNew = async (values: any) => {
    const hide = message.loading(t('Creating'));
    const payload: API.NewCustomClient = {
      name: values.name,
      os: os,
      info: { options: {}, ...values.info },
      note: values.note,
    };
    try {
      await newCustomClient(fillPayload(values, payload) as API.NewCustomClient);
      hide();
      message.success(t('Create successfully!'));
      setPagination({ ...pagination, current: 1 });
      gotoTablePage();
    } catch (e) {
      hide();
      message.error(plain('Failed to create') + ': ' + e);
    }
  }
  const onEdit = async () => {
    if (currentRow == undefined) {
      // unreachable!
      message.error(plain('Failed to edit') + ', ' + plain('invalid row'));
      gotoTablePage();
    } else {
      gotoPage(CustomClientPage.PageEdit, currentRow.guid);
    }
  }
  const onSave = async (values: any) => {
    if (currentRow?.guid == undefined) {
      // unreachable!
      message.error(plain('Failed to update') + ', ' + plain('invalid GUID'));
      gotoTablePage();
    } else {
      const hide = message.loading(t('Updating'));
      const payload: API.UpdateCustomClient = {
        name: values.name,
        info: { options: {}, ...values.info },
        note: values.note,
      };
      try {
        await updateCustomClient(currentRow?.guid, fillPayload(values, payload) as API.UpdateCustomClient);
        hide();
        message.success(t('Update successfully!'));
        gotoTablePage();
      } catch (e) {
        hide();
        message.error(plain('Failed to update') + ', ' + e);
      }
    }
  }

  const onFinish = async (values: any) => {
    switch (page) {
      case CustomClientPage.PageNew:
      case CustomClientPage.PageDup:
        await onNew(values);
        break;
      case CustomClientPage.PageEdit:
        await onSave(values);
        break;
      case CustomClientPage.PageView:
        await onEdit();
        break;
      default:
        gotoTablePage();;
    }
  }

  const onCancel = () => gotoTablePage();
  const content = <>
    <ProCard
      direction='column'
      ghost
      gutter={8}
    >
      {
        supportedOs.length > 1 && <ProCard
          title={plain('OS')}
          bordered
        >
          <OsCard
            token={token}
            page={page}
            supportedOs={supportedOs}
            os={os}
            setOs={setOs}
            plain={plain}
          />
        </ProCard>
      }
      <ProCard gutter={12} style={{ marginBlockStart: marginBlockStart }}>
        <ProCard
          direction='column'
          ghost
        >
          <ProCard
            title={plain('General')}
            bordered
          >
            <GeneralCard
              token={token}
              page={page}
              currentRow={currentRow}
              connType={connType}
              setConnType={setConnType}
              supportedOs={supportedOs}
              os={os}
              usedNames={usedNames}
              plain={plain}
              form={form}
            />
          </ProCard>
        </ProCard>
        <ProCard
          direction='column'
          ghost
        >
          <ProCard
            title={plain('Custom server')}
            bordered
          >
            <CustomServerCard
              page={page}
              currentRow={currentRow}
              serverProps={serverProps}
              plain={plain}
            />
          </ProCard>
          <ProCard
            title={plain('Visual')}
            bordered
            style={{ marginBlockStart: marginBlockStart }}
          >
            <VisualCard
              page={page}
              currentRow={currentRow}
              appIcon={appIcon}
              setAppIcon={setAppIcon}
              logo={logo}
              setLogo={setLogo}
              plain={plain}
              form={form}
              os={os}
            />
          </ProCard>
          {
            connType != CustomClientType.Outgoing && <ProCard
              title={plain('Security')}
              bordered
              style={{ marginBlockStart: 8 }}
            >
              <SecurityCard
                page={page}
                currentRow={currentRow}
                presetPassword={presetPassword}
                setPresetPassword={setPresetPassword}
                plain={plain}
              />
            </ProCard>
          }
          <ProCard
            title={plain('Advanced')}
            bordered
            style={{ marginBlockStart: marginBlockStart }}
            tooltip={<a href="https://rustdesk.com/docs/en/self-host/client-configuration/advanced-settings/" target="blank">{plain("Document")}</a>}
          >
            <AdvancedCard
              page={page}
              currentRow={currentRow}
              overrideSettings={overrideSettings}
              setOverrideSettings={setOverrideSettings}
              defaultSettings={defaultSettings}
              setDefaultSettings={setDefaultSettings}
              plain={plain}
            />
          </ProCard>
        </ProCard>
      </ProCard>
    </ProCard>
  </>;

  const submitText = () => {
    switch (page) {
      case CustomClientPage.PageEdit:
        return t('Save');
      case CustomClientPage.PageNew:
      case CustomClientPage.PageDup:
        return t('Create');
      case CustomClientPage.PageView:
        return t('Edit');
      default:
        return '';
    }
  }

  return loading ?
    <div style={{ width: '100%', textAlign: "center" }}><Spin /></div> :
    <ProForm
      form={form}
      onFinish={onFinish}
      onReset={onCancel}
      submitter={{
        searchConfig: {
          submitText: submitText(),
          resetText: t('Back'),
        },
        render: (_, dom) => {
          return <div style={{ textAlign: 'right', marginTop: '12px' }}>
            <Space>
              {dom[0]}
              {dom[1]}
              {page == CustomClientPage.PageView && getDelete(
                <Button style={{ color: 'red' }}>{t('Delete')}</Button>,
                currentRow?.guid ?? '',
                actionRef,
                () => gotoTablePage())
              }
            </Space>
          </div>;
        },
      }}
    >
      {content}
    </ProForm>;
};

export default CustomClientDetails;
