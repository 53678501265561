import { devices, abAddPeer } from "@/services/api"
import { Button, Col, message, Modal, Row, Typography } from "antd"
import { useEffect, useRef, useState } from "react"
import { API } from '@/services/typings';
import ProTable, { ActionType } from "@ant-design/pro-table";
import { getTablePaginationConfig, t } from "@/global";
import { CloseOutlined } from "@ant-design/icons";
import { DEVICE_LIST_SORT_STATE_KEY, deviceColumns } from "@/pages/DeviceList/DevicesTable";
import { useModel } from "@umijs/max";
import { getLocalSortState, handleSortStateChange } from "@/components/Others/table_sort";

type DevicesProps = {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  ab_guid: string,
  abName?: string,
  closeChangedCallback: () => void;
}

const app: React.FC<DevicesProps> = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [everChanged, setEverChanged] = useState<boolean>(false);
  const actionRef = useRef<ActionType>();
  const { initialState } = useModel('@@initialState');
  const is_admin = initialState?.user?.is_admin;
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [sortState, setSortState] = useState<API.SortState>(() => getLocalSortState(DEVICE_LIST_SORT_STATE_KEY));

  useEffect(() => {
    if (props.visible) {
      actionRef.current?.reload();
    }
  }, [props.visible]);

  const onSave = async () => {
    const hide = message.loading(t('Updating'));
    try {
      const ids = selectedRowKeys;
      for (const id of ids) {
        const peer = {
          id: id,
        };
        await abAddPeer(props.ab_guid, { data: peer });
      }
      hide();
      message.success(`${ids.length} record${ids.length === 1 ? " is" : "s are"} successfully changed`)
    } catch (e: any) {
      hide();
      message.error(e);
    }
    setSelectedRowKeys([])
    setEverChanged(true);
    actionRef.current?.reload();
  }

  const onReset = async () => {
    actionRef.current?.reset?.();
  }

  const saveButtonEnabled = () => {
    return selectedRowKeys.length > 0;
  }

  const onClose = () => {
    props.setVisible(false);
    setSelectedRowKeys([]);
    if (everChanged) props.closeChangedCallback();
    setEverChanged(false);
  }

  const columns = deviceColumns({
    actionRef,
    is_admin: is_admin,
    is_select: true,
    totalRecords,
    sortState,
    setSortState,
  });

  return <Modal
    open={props.visible}
    width="90vw"
    style={{
      overflowX: "hidden",
    }}
    footer={saveButtonEnabled() ?
      <Row justify="center" gutter={16}>
        <Col>
          <Button onClick={onReset}>{t("Reset")}</Button>
        </Col>
        <Col>
          <Button onClick={onSave} type="primary">{t("Save")}</Button>
        </Col>
      </Row>
      : null}
    onCancel={onClose}
    destroyOnClose={true}
    title={<span>Add or remove devices for <Typography.Text mark>{props.abName}</Typography.Text></span>}
    closeIcon={<CloseOutlined />}
  >
    <ProTable<API.DeviceListItem, API.PageParams | & { accessible?: string, status: string, not_in_ab: string }>
      columnsState={{ persistenceType: 'localStorage', persistenceKey: ' address_book_edit_devices_columns_state' }}
      rowKey={"id"}
      actionRef={actionRef}
      search={{ filterType: 'light' }}
      defaultSize="middle"
      columns={columns as any}
      params={{
        accessible: is_admin ? undefined : "",
        status: "1",
        not_in_ab: props.ab_guid,
      }}
      request={async (params, sort, _filter) => {
        const result = await devices(params, sort);
        setTotalRecords(result.total || 0);
        return result;
      }}
      postData={(items: (API.DeviceListItem)[]) => {
        return items;
      }}
      rowSelection={{
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys) => {
          setSelectedRowKeys(selectedRowKeys as string[]);
        },
      }}
      pagination={getTablePaginationConfig('address_book_device_list')}
      style={{
        paddingLeft: '5vw', paddingRight: '5vw'
      }}
      onChange={(_, __, sorter) => handleSortStateChange(sorter, actionRef, DEVICE_LIST_SORT_STATE_KEY, setSortState)}
    />
  </Modal >
}

export default app;

