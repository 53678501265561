import type { CSSProperties } from 'react';
import React, { useState, useRef } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import type { ActionType } from '@ant-design/pro-table';
import { useIntl, useModel, history } from '@umijs/max';
import CustomClientDetails from './Commponents/Details';
import CustomClientTable from './Commponents/Table';
import { Breadcrumb, Modal, Popconfirm, message, theme } from 'antd';
import { t, urlEncodeRecords, urlRenewUpgradeLicense } from '@/global';
import { deleteCustomClient } from '@/services/api';
import queryString from 'query-string';
import { AndroidFilled, AppleFilled, QqCircleFilled, WindowsFilled } from '@ant-design/icons';
import type { Search } from 'history';

export const MIN_USERS_REQUIRED: number = 10;
export const MIN_PEERS_REQUIRED: number = 200;

const FORMAT_ID_PREFIX = 'pages.CustomClient.';

export type FuncFormatMessage = (id: string, defaultMessage?: string, formatIdPrefix?: string) => string;

export type ConfigItem<T> = {
  key: string;
  label: string;
  desc: string;
  emphasized?: boolean;
  formatIdPrefix?: string;
  default: T;
}

export enum OptionKeys {
  DisableInstallation = 'disable-installation',
  DisableSettings = 'disable-settings',
  DisableAB = 'disable-ab',
  DisableTCPListen = 'disable-tcp-listen',
  DisableAccount = 'disable-account',
}

export const options: ConfigItem<boolean>[] = [
  {
    key: OptionKeys.DisableInstallation,
    label: 'Disable installation',
    default: false,
    desc: 'disable_installation_desc_tip',
    emphasized: true,
  },
  {
    key: OptionKeys.DisableSettings,
    label: 'Disable settings',
    default: false,
    desc: 'disable_settings_desc_tip',
  },
  {
    key: OptionKeys.DisableAB,
    label: 'Disable address book',
    default: false,
    desc: 'disable_ab_desc_tip',
  },
  {
    key: OptionKeys.DisableTCPListen,
    label: 'Disable TCP listen port',
    default: false,
    desc: 'disable_tcp_desc_tip',
  },
  {
    key: OptionKeys.DisableAccount,
    label: 'Disable user account',
    default: false,
    desc: 'disable_account_desc_tip',
  },
]

export enum CustomClientType {
  IncomingOutgoing = 1,
  Incoming = 2,
  Outgoing = 3,
}

export const isFixedOption = (connType: CustomClientType, key: string) => {
  const isTrueOpts: Record<number, string[]> = {
    [CustomClientType.Incoming]: [OptionKeys.DisableAB],
  };
  if ((isTrueOpts[connType] ?? []).includes(key)) {
    return true;
  }
  return undefined;
}

export const isIgnored = (connType: CustomClientType, key: string) => {
  const isIgnoredOpts: Record<number, string[]> = {
    [CustomClientType.Outgoing]: [OptionKeys.DisableTCPListen],
  };
  return (isIgnoredOpts[connType] ?? []).includes(key);
}

export enum CustomClientOs {
  Windows = 1,
  MacOS = 2,
  Linux = 3,
  Android = 4,
  IOS = 5,
}

export const os2Desc = (os: CustomClientOs, plain: FuncFormatMessage) => {
  switch (os) {
    case CustomClientOs.Windows:
      return plain('Windows');
    case CustomClientOs.MacOS:
      return plain('MacOS');
    case CustomClientOs.Linux:
      return plain('Linux');
    case CustomClientOs.Android:
      return plain('Android');
    case CustomClientOs.IOS:
      return plain('IOS');
    default:
      return '';
  }
};

export const os2Icon = (os: CustomClientOs, style?: CSSProperties) => {
  switch (os) {
    case CustomClientOs.Windows:
      return <WindowsFilled style={style} />;
    case CustomClientOs.Linux:
      return <QqCircleFilled style={style} />;
    case CustomClientOs.Android:
      return <AndroidFilled style={style} />;
    case CustomClientOs.MacOS:
      return <AppleFilled style={style} />;
    case CustomClientOs.IOS:
      return <AppleFilled style={style} />;
    default:
      return undefined;
  }
};

export type ConfigValueProps = {
  label: string;
  desc: string;
  formatIdPrefix?: string;
}

export const typeConfigValues: Record<CustomClientType, ConfigValueProps> = {
  [CustomClientType.IncomingOutgoing]: {
    label: 'Bidirectional',
    desc: 'bidirectional_desc_tip',
  },
  [CustomClientType.Incoming]: {
    label: 'Incoming',
    desc: 'incoming_desc_tip',
  },
  [CustomClientType.Outgoing]: {
    label: 'Outgoing',
    desc: 'outgoing_desc_tip',
  },
}

export enum CustomClientPage {
  PageTable = 'table',
  PageView = 'view',
  PageNew = 'new',
  PageEdit = 'edit',
  PageDup = 'dup',
}

export const gotoTablePage = () => {
  history.push('/custom-client');
}

export const gotoPage = (page: CustomClientPage, id?: string) => {
  history.push(`/custom-client?${urlEncodeRecords({ page, id })}`);
}

export type CustomClientPageProps = {
  page: CustomClientPage;
  dataId: string;
}

export type Pagination = {
  current?: number;
  pageSize?: number;
}

// Duplicated code from src/pages/GroupList/index.tsx
const DeleteGroups = (props: { children: any; onClick: () => void }) => (
  <Popconfirm
    title={t('deleteConfirm', true, 'Are you sure to delete?')}
    onConfirm={() => props.onClick()}
    okText="Yes"
    cancelText="No"
  >
    {props.children}
  </Popconfirm>
);

export const getDelete = (
  child: React.ReactNode,
  guid: string,
  actionRef: React.MutableRefObject<ActionType | undefined>,
  onSuccess?: () => void,
) => <>
    <DeleteGroups
      onClick={async () => {
        const hide = message.loading(t('Deleting...'));
        try {
          await deleteCustomClient(guid);
          actionRef.current?.reloadAndRest?.();
          hide();
          message.success(t('Deleted successfully!'));
          onSuccess?.();
        } catch (e) {
          hide();
          message.error(t('Failed to delete') + ', ' + e);
        }
      }}
    >
      {child}
    </DeleteGroups>
  </>;

export const getUpgradeLicenseMessage = (
  plain: FuncFormatMessage
) => plain(`To use this feature, you need to upgrade your license to basic plan or above.`);

const CustomClient: React.FC = () => {
  const intl = useIntl();
  const plain = (text: string, defaultMessage?: string, formatIdPrefix?: string) =>
    t(`${formatIdPrefix ?? FORMAT_ID_PREFIX}${text}`, true, defaultMessage || text, intl) as string;

  const { initialState } = useModel('@@initialState');
  const actionRef = useRef<ActionType>();
  const maxUsers = initialState?.license?.max_users ?? 0;
  const maxPeers = initialState?.license?.max_peers ?? 0;
  const isAvailable = maxUsers >= MIN_USERS_REQUIRED || maxPeers >= MIN_PEERS_REQUIRED;
  const [showLicUpgradeModal, setShowLicUpgradeModal] = useState(!isAvailable);
  const [pagination, setPagination] = useState<Pagination>({});
  const [currentName, setCurrentName] = useState<string | undefined>(undefined);

  const getPageProps = (search: Search) => {
    const query = queryString.parse(search);
    const page = (query?.page ?? CustomClientPage.PageTable) as CustomClientPage;
    const dataId = (query?.id ?? '') as string;
    if ([
      CustomClientPage.PageView,
      CustomClientPage.PageNew,
      CustomClientPage.PageEdit,
      CustomClientPage.PageDup
    ].includes(page)) {
      return { page, dataId };
    } else {
      return { page: CustomClientPage.PageTable, dataId };
    }
  }
  const pageProps = getPageProps(history.location.search);

  const { useToken } = theme;
  const { token } = useToken();

  const getPage = () => {
    switch (pageProps.page) {
      case CustomClientPage.PageTable:
        return <CustomClientTable
          pagination={pagination}
          setPagination={setPagination}
          isAvailable={isAvailable}
          plain={plain}
          actionRef={actionRef} />;
      default:
        return <CustomClientDetails
          token={token}
          pageProps={pageProps}
          pagination={pagination}
          setPagination={setPagination}
          plain={plain}
          actionRef={actionRef}
          setCurrentName={setCurrentName}
        />;
    }
  }

  const licenseUpgradeModal = <Modal
    open={showLicUpgradeModal}
    title={plain('Upgrade License')}
    maskClosable={false}
    onCancel={() => setShowLicUpgradeModal(false)}
    cancelText={plain('Close')}
    onOk={() => {
      setShowLicUpgradeModal(false);
      window.open(urlRenewUpgradeLicense, '_blank');
    }}
    okText={plain('Upgrade')}
  >
    {getUpgradeLicenseMessage(plain)}
  </Modal>;

  const breadcrumbRender = () => {
    if (pageProps.page == CustomClientPage.PageTable) return undefined;
    let title = '';
    switch (pageProps.page) {
      case CustomClientPage.PageView:
        if (!currentName) return undefined;
        title = currentName;
        break;
      case CustomClientPage.PageNew:
        title = plain('Create');
        break;
      case CustomClientPage.PageEdit:
        if (!currentName) return undefined;
        title = `${plain('Edit')} ${currentName}`;
        break;
      case CustomClientPage.PageDup:
        if (!currentName) return undefined;
        title = `${plain('Duplicate')} ${currentName}`;
        break;
    }
    return <Breadcrumb items={[
      {
        title: <a onClick={() => gotoTablePage()}>{plain('Custom Clients')}</a>
      },
      {
        title: title
      }
    ]} />;
  }

  return <PageContainer title={pageProps.page == CustomClientPage.PageTable ? undefined : false} breadcrumbRender={breadcrumbRender}>
    {getPage()}
    {showLicUpgradeModal && licenseUpgradeModal}
  </PageContainer>;
};

export default CustomClient;
