import { t } from "@/global";
import { abAddPeer, abUpdatePeer } from "@/services/api";
import { API } from "@/services/typings";
import { ModalForm, ProFormItem, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { ActionType } from "@ant-design/pro-table";
import { Flex, message, Checkbox } from "antd";
import { buildTag } from "./TagsCard";
import { useEffect, useState } from "react";
import { useIntl } from "@umijs/max";
import { WarningOutlined } from "@ant-design/icons";

type CreateOrUpdateFormProps = {
  isCreate: boolean;
  visible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  actionRef: React.MutableRefObject<ActionType | undefined>;
  personal: boolean;
  profile: API.SharedAbProfile;
  initialValues: API.AbPeer | undefined;
  abTags: API.AbTag[];
}

const CreateOrUpdateForm: React.FC<CreateOrUpdateFormProps> = (props) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [initialValues, setInitialValues] = useState<API.AbPeer | undefined>();
  const [removePassword, setRemovePassword] = useState(false);
  const intl = useIntl();

  const plain = (text: string, defaultMessage = '') => t(`pages.AddressBook.${text}`, true, defaultMessage || text, intl) as string;
  useEffect(() => {
    if (props.visible) {
      setRemovePassword(false);
    }
  }, [props.visible]);

  useEffect(() => {
    setSelectedTags(props.initialValues?.tags ?? []);
  }, [props.initialValues?.tags, props.visible]);

  useEffect(() => {
    // Always set initial password to undefined
    if (props.initialValues) {
      let newValue = { ...props.initialValues };
      delete newValue.password;
      setInitialValues(newValue);
    } else {
      setInitialValues(undefined);
    }
  }, [props.initialValues])

  const handleCreate = async (data: any) => {
    const hide = message.loading(t('Adding'));
    try {
      let id = data.id as string;
      id = id.trim().replaceAll(' ', '');
      const peer = {
        id: id,
        password: props.personal ? undefined : data.password,
        alias: data.alias,
        note: data.note,
        tags: selectedTags,
      };
      await abAddPeer(props.profile.guid, { data: peer });
      hide();
      message.success(t('Added successfully'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Adding failed, please try again!'),
      );
      return false;
    }
  };

  const handleUpdate = async (data: any) => {
    const hide = message.loading(t('Updating'));
    try {
      let password = data.password;
      if (removePassword) {
        password = ''; // remove password
      } else if (password === '') {
        password = undefined; // keep password unchanged
      }
      const peer = {
        id: data.id,
        alias: data.alias,
        password: password,
        note: data.note,
        tags: selectedTags,
      };
      await abUpdatePeer(props.profile.guid, { data: peer });
      hide();
      message.success(t('Update is successful'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Update failed, please try again!'),
      );
      return false;
    }
  };

  const buildTagWrapper = (abTag: API.AbTag) => {
    const selected = selectedTags.includes(abTag.name);
    return buildTag(abTag, selected, () => {
      if (selected) {
        setSelectedTags(selectedTags.filter(tag => tag !== abTag.name));
      } else {
        setSelectedTags([...selectedTags, abTag.name]);
      }
    }, undefined, undefined);
  }

  return (
    <ModalForm
      title={t(props.isCreate ? 'Add' : 'Edit')}
      width="400px"
      open={props.visible}
      initialValues={initialValues}
      onOpenChange={props.setModalVisible}
      onFinish={async (values) => {
        const success = await (props.isCreate ? handleCreate : handleUpdate)(values);
        if (success) {
          if (props.actionRef.current) {
            props.actionRef.current.reload();
          }
          props.setModalVisible(false);
        }
      }}
      preserve={false}
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        onCancel: () => {
          props.setModalVisible(false);
        },
      }}
    >
      <ProFormText
        fieldProps={{ autoComplete: 'off' }}
        readonly={!props.isCreate}
        rules={[
          {
            required: true,
            whitespace: false,
          },
          {
            min: 6,
            max: 16,
          },
        ]}
        width="md"
        name="id"
        label={t('ID', true)}
      />
      <ProFormText
        fieldProps={{ autoComplete: 'off' }}
        rules={[
          {
            required: false,
            whitespace: false,
          },
          {
            max: 30,
          },
        ]}
        width="md"
        name="alias"
        label={plain('Alias')}
      />
      {!props.personal && !(!props.isCreate && removePassword) && <ProFormText.Password
        fieldProps={{ autoComplete: 'new-password', maxLength: 128, type: 'password' }}
        rules={[
          {
            required: false,
            whitespace: false,
          }
        ]}
        width="md"
        name="password"
        label={t(!props.isCreate ? 'New Password' : 'Password', true)}
      />}
      {!props.personal && !props.isCreate && <ProFormItem>
        <Checkbox value={removePassword} onChange={(e) => setRemovePassword(e.target.checked)}>
          {plain('Remove password')}
        </Checkbox>
      </ProFormItem>}
      <ProFormTextArea
        fieldProps={{ autoComplete: 'off' }}
        rules={[
          {
            max: 100,
          },
        ]}
        width="md"
        name="note"
        label={t('Note', true)}
      />
      {props.abTags.length > 0 && <ProFormItem>{plain('Tags')}</ProFormItem>}
      {props.abTags.length > 0 && <ProFormItem>
        <Flex wrap="wrap" gap="small">
          {props.abTags.map((tag, i) => (
            buildTagWrapper(tag,)
          ))}
        </Flex>
      </ProFormItem>}
      {!props.personal && <ProFormItem>
        <WarningOutlined style={{ color: 'orange', marginRight: 10 }} />
        {plain("share_warning_tip", "The fields above are shared and visible to others.")}
      </ProFormItem>}
    </ModalForm>
  )
}

export default CreateOrUpdateForm;
