import { t } from "@/global";
import { deleteAbTags, abAddTag, getAbTags, abUpdateTag } from "@/services/api";
import { API } from "@/services/typings";
import ProCard from "@ant-design/pro-card";
import { ActionType } from "@ant-design/pro-table";
import { useIntl } from "@umijs/max";
import { Button, Checkbox, ColorPicker, Flex, Input, Popconfirm, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { ShareRule } from "../Profiles/RuleForm";
import { CloseOutlined } from "@ant-design/icons";

type TagsCardProps = {
  personal: boolean,
  profile: API.SharedAbProfile;
  abTags: API.AbTag[];
  setAbTags: React.Dispatch<React.SetStateAction<API.AbTag[]>>;
  deviceTableActionRef: React.MutableRefObject<ActionType | undefined>;
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  intersection: boolean;
  setIntersection: React.Dispatch<React.SetStateAction<boolean>>;
};

const kUntagged = 'Untagged';

const TagsCard: React.FC<TagsCardProps> = (props) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const canEdit = props.personal || (props.profile.rule == ShareRule.READ_WRITE || props.profile.rule == ShareRule.FULL_CONTROL);
  const intl = useIntl();

  const plain = (text: string, defaultMessage = '') => t(`pages.AddressBook.${text}`, true, defaultMessage || text, intl) as string;

  useEffect(() => {
    getTags();
  }, []);

  const getTags = async () => {
    try {
      props.setAbTags(await getAbTags(props.profile.guid));
    } catch (error) {
      message.error(t(`Failed to get tags: ${error}`));
    }
  };

  const handleAdd = async (name: string) => {
    let trimedName = name.trim();
    if (trimedName.length === 0) {
      message.error(t('Tag name cannot be empty'));
      return;
    }
    if (trimedName == kUntagged) {
      message.error(t(`Tag name cannot be ${kUntagged}`));
      return;
    }
    if (props.abTags.map(tag => tag.name).includes(trimedName)) {
      message.error(t(`Tag ${trimedName} already exists`));
      return;
    }
    const hide = message.loading(t('Adding'));
    try {
      const tag: API.AbTag = {
        name: trimedName,
        color: toU32(str2color2(trimedName, props.abTags.map(tag => tag.color))),
      };
      await abAddTag(props.profile.guid, { data: tag });
      getTags();
      hide();
      message.success(t('Added successfully'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Adding failed, please try again!'),
      );
      return false;
    }
  };

  const handleUpdate = async (tag: API.AbTag) => {
    const hide = message.loading(t('Updating'));
    try {
      await abUpdateTag(props.profile.guid, { data: tag });
      getTags();
      hide();
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Update failed, please try again!'),
      );
      return false;
    }
  };

  const handleDelete = async (name: string) => {
    const hide = message.loading(t('Deleting'));
    try {
      await deleteAbTags(
        props.profile.guid,
        {
          data: [name],
        },
      );
      getTags();
      hide();
      message.success(t('Deletetion is successful'));
      // reload device table because tags of peer are deleted at the same time
      props.deviceTableActionRef?.current?.reload();
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Deletetion failed, please try again!'),
      );
      return false;
    }
  };

  const buildTagWrapper = (abTag: API.AbTag) => {
    const selected = props.selectedTags.includes(abTag.name);
    const item = buildConfirmableTag(abTag, selected, canEdit,
      () => {
        handleDelete(abTag.name);
        props.setSelectedTags(props.selectedTags.filter(tag => tag !== abTag.name));
      },
      () => {
        if (selected) {
          props.setSelectedTags(props.selectedTags.filter(tag => tag !== abTag.name));
        } else {
          props.setSelectedTags([...props.selectedTags, abTag.name]);
        }
      },
      canEdit,
      handleUpdate);
    return item;
  }

  const buildUntagged = () => {
    const name = kUntagged;
    const selected = props.selectedTags.includes(name);
    return <Tag
      color={selected ? '#1890ff' : undefined}
      style={{ display: 'inline-flex', alignItems: 'center', cursor: 'default' }}
      onClick={() => {
        if (selected) {
          props.setSelectedTags(props.selectedTags.filter(tag => tag !== name));
        } else {
          props.setSelectedTags([...props.selectedTags, name]);
        }
      }}
    > <span>{plain(name)}</span></ Tag >
  }

  const handleInputConfirm = async () => {
    const trimedValue = inputValue.trim();
    if (trimedValue.length > 0) {
      handleAdd(trimedValue);
    }
    setInputValue('');
    setInputVisible(false);
  }

  return <ProCard
    title={plain('Tags')}
    extra={props.selectedTags.length > 1 && <Checkbox
      checked={props.intersection}
      onChange={(e) => { props.setIntersection(e.target.checked) }}>
      {plain("Filter by intersection")}
    </Checkbox>}
  >
    <Flex wrap="wrap" gap="small">
      {[buildUntagged(), ...props.abTags.map((tag, i) => (
        buildTagWrapper(tag)
      ))]}
      {canEdit && (inputVisible ? (
        <Input
          type="text"
          size="small"
          autoFocus
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => handleInputConfirm()}
          onPressEnter={() => handleInputConfirm()}
          style={{ width: 200, margin: "6px 0" }}
          placeholder={"Add tag"}
        />
      ) : (
        <Button onClick={() => { setInputVisible(true) }} size="small">
          <strong>+</strong>
        </Button>)
      )}
    </Flex>
  </ProCard >
}


export default TagsCard;

export function numberColorToARGB(color: number, withAplah: boolean): string {
  const alpha = (color >> 24) & 0xff;
  const red = (color >> 16) & 0xff;
  const green = (color >> 8) & 0xff;
  const blue = color & 0xff;
  return `#${withAplah ? alpha.toString(16).padStart(2, '0') : ''}${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
}

function str2color2(str: string, existing: number[] = []): number {
  const colorMap: { [key: string]: number } = {
    red: 0xFFFF0000,
    green: 0xFF008000,
    blue: 0xFF0000FF,
    orange: 0xFFFF9800,
    purple: 0xFF9C27B0,
    grey: 0xFF9E9E9E,
    cyan: 0xFF00BCD4,
    lime: 0xFFCDDC39,
    teal: 0xFF009688,
    pink: 0xFFF48FB1,
    indigo: 0xFF3F51B5,
    brown: 0xFF795548,
  };

  const lowerCaseStr = str.toLowerCase();
  const color = colorMap[lowerCaseStr];
  if (color) {
    return color;
  }

  if (lowerCaseStr === 'yellow') {
    return 0xFFFFFF00;
  }

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }

  const colorList = Object.values(colorMap);
  hash = hash % colorList.length;
  let result = colorList[hash];

  if (existing.includes(result)) {
    const notUsed = colorList.find(e => !existing.includes(e));
    if (notUsed) {
      result = notUsed;
    }
  }

  return result;
}

function convertHexToARGB(hex: string): number | undefined {
  try {
    if (hex.length !== 7) {
      return undefined;
    }
    const red = parseInt(hex.slice(1, 3), 16);
    const green = parseInt(hex.slice(3, 5), 16);
    const blue = parseInt(hex.slice(5, 7), 16);
    const alpha = 255;
    const argb = toU32((alpha << 24) | (red << 16) | (green << 8) | blue);
    return argb;
  } catch (_) {
  }
  return undefined;
}

function toU32(value: number): number {
  return value >>> 0;
}

export const buildTag = (abTag: API.AbTag, selected: boolean, onClick: (() => void) | undefined,
  closable: boolean | undefined, onClose: ((e: React.MouseEvent<HTMLElement>) => void) | undefined) => {
  const color = numberColorToARGB(abTag.color, false);
  return <Tag
    color={selected ? color : undefined}
    style={{ display: 'inline-flex', alignItems: 'center', cursor: onClick ? 'pointer' : 'default' }}
    onClick={onClick}
    closable={closable}
    onClose={onClose}
  >
    <span
      style={{
        display: 'inline-block',
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: selected ? '#ffffff' : color,
        marginRight: 8,
      }}
    ></span>
    {abTag.name}
  </Tag>;
}

const buildConfirmableTag = (abTag: API.AbTag, selected: boolean, closable: boolean | undefined,
  onConfirm: (e?: React.MouseEvent<HTMLElement>) => void, onClick: (() => void), canEdit: boolean, handleUpdate: (tag: API.AbTag) => Promise<boolean>) => {
  const color = numberColorToARGB(abTag.color, false);
  const dot = <span onClick={onClick}><span
    style={{
      display: 'inline-block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: selected ? '#ffffff' : color,
    }}
  ></span>
    <span style={{ display: 'inline-block', width: 8 }}></span>
  </span>;
  return <Tag
    color={selected ? color : undefined}
    style={{ display: 'inline-flex', alignItems: 'center', cursor: 'default', paddingLeft: 0 }}
  >
    <span onClick={onClick} style={{ paddingLeft: 6 }}>
      {canEdit ?
        <span
          onClick={(e) => e.stopPropagation()}
        >
          <ColorPicker
            key={abTag.name}
            value={numberColorToARGB(abTag.color, false)}
            trigger="hover"
            onChangeComplete={(color) => {
              let hex = color.toHexString(false); // #1677ff
              let number = convertHexToARGB(hex);
              if (number === undefined) {
                message.error(t(`Invalid color ${hex}`));
                return;
              }
              if (number !== abTag.color) {
                const newAbTag: API.AbTag = {
                  name: abTag.name,
                  color: toU32(number)
                };
                handleUpdate(newAbTag);
              }
            }}>{dot}</ColorPicker></span> : dot}
      <span >{abTag.name}</span>
    </span>
    {closable && <Popconfirm
      title={t('deleteConfirm', true, 'Are you sure to delete?')}
      onConfirm={onConfirm}
      okText="Yes"
      cancelText="No"
    >
      <CloseOutlined style={{ marginLeft: 6 }} />
    </Popconfirm>}
  </Tag>;
}
